<template>
  <div class="protocol">
    <h1>隐私政策</h1>
    <time><span>更新日期：2022年7月25日</span><span>生效日期：2022年7月25日</span></time>
    <h2>引言</h2>
    <p>欢迎您使用商政通（https://zc.vipisall.com），商政通是由重庆维普资讯有限公司开发并管理，商政通对每一位用户的隐私权采取高度尊重且保护的态度。重庆维普资讯有限公司（以下简称“维普资讯”）非常重视用户的个人信息和隐私保护，为同时给您提供更准确、有个性化的服务和更安全的互联网环境，我们依据《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》（GB/T 35273-2017）以及其他相关法律法规和技术规范，将《隐私政策》相关内容进行了更新，进一步明确了收集/使用/对外提供的原则，进一步阐述了关于您个人信息的相关权利等。</p>
    <p>您在使用商政通的产品或服务时，商政通可能会收集和使用您的相关信息。为此，希望通过本《隐私政策》向您说明，商政通如何取得、使用、分享、保存和管理这些信息。 <strong>请在使用我们的各项产品与服务前，仔细阅读并充分理解本政策（尤其是加粗提示的内容），并在需要时，按照本政策的指引，做出适当的选择。如果您不同意本政策的内容，将可能导致我们的产品或服务无法正常运行，无法达到最优的服务效果，您应立即停止访问/使用我们的产品或服务。您继续使用我们提供的产品或服务的行为，都表示您充分理解并同意本《隐私政策》（包括更新版本）的全部内容。</strong></p>

    <p>本《隐私政策》将帮助您了解以下内容：</p>

    <h3>第一章 如何收集您的个人信息</h3>
    <h3>第二章 如何使用您的个人信息</h3>
    <h3>第三章 如何共享、转让、公开披露您的个人信息</h3>
    <h3>第四章 如何保护您的个人信息</h3>
    <h3>第五章 如何保存您的个人信息</h3>
    <h3>第六章 关于cookies的使用</h3>
    <h3>第七章 管理、查看或删除您的个人信息</h3>
    <h3>第八章 本政策如何更新</h3>
    <h3>第九章 投诉及建议</h3>

    <h2>第一章 如何收集您的个人信息</h2>

    <h3>1.1 您向商政通提供相关个人信息</h3>

    <p>1.1.1 为使用商政通的产品或服务，<strong><u>建议您首先注册账号并登录；</u></strong></p>
    <p>1.1.2 <strong><u>如果您选择手机登录方式，您需向我们提供您的手机号码。我们收集这一信息是为了识别和验证您的身份。</u></strong>这也有助于商政通将您的手机号码与商政通账号绑定，以此实现该账号项下的数据在不同设备之间的同步；</p>
    <p>1.1.3 在注册完成后，<strong><u>您还可以选择修改或补充您的昵称、手机号、邮箱、行业信息（但不提供这些信息不影响您使用商政通的产品或服务），这些信息均属于您的账号信息。</u></strong>对于该等账号信息所涉的个人信息，商政通将按照本《隐私政策》予以保护；</p>
    <p>1.1.4您购买商政通提供的收费产品或服务时，您可能需要向商政通提供您的账号信息、手机号。为了帮助您顺利完成交易、保障您的交易安全、查询订单信息等，在您下单交易时，我们会<strong><u>收集您的交易商品/服务信息、交易时间、订单号、交易金额、支付方式、联系电话、第三方支付信息；</u></strong></p>
    <p>1.1.5 在您使用商政通产品或服务时，您可能会因账号管理、产品使用等问题联系商政通客服。为此，您可能<strong><u>需要向商政通提供姓名、手机号码、充值记录、购买记录、支付记录、系统操作记录、第三方支付方式（支付宝支付、微信支付），</u></strong>便于商政通的客服人员与您联系，并帮助您解决相关问题。如果您拒绝提供相关信息，商政通可能无法帮助您解决相关问题。</p>

    <h3>1.2 商政通通过调用设备权限收集您的相关个人信息</h3>

    <p>1.2.1 为提升商政通产品或服务的使用体验和便利程度，商政通可能会通过调用设备权限的方式收集您的如下个人信息。<strong><u>如果您不同意提供这些个人信息，您可能无法使用与提升用户体验相关的功能；</u></strong></p>
    <p>1.2.2 若您希望通过移动网络或移动运营商信息使自己账号与机构关联，免费获取机构使用权限。<strong><u>您需要通过PC设备连接该机构局域网络，并使终端处于该网络环境内进行机构权限绑定；</u></strong></p>
    <p>1.2.3 若您同意向商政通下载、上传您的文档资料，您<strong><u>需选择授权商政通调用您所使用设备的存储权限；</u></strong></p>
    <p>1.2.4 为学术研究、应用研究、产业服务的需要，商政通可能采集您提供的<strong><u>姓名、性别、身份证号、实人信息、学历、专业、研究领域、供职机构、所属行业，</u></strong>但商政通承诺此类信息不对外第三方提供；</p>
    <p>1.2.5 为向您推荐合适的产品或服务和履行双方间签订的协议，商政通将<strong><u>采集您的个人检索、浏览记录，包括：您在商政通产品及/或服务浏览记录、检索记录、点击记录、学习数据，以便于您重复检索和浏览相关文献。</u></strong></p>

    <h3>1.3 第三方向您收集的信息</h3>

    <p>1.3.1 第三方付款服务提供商收集相关个人信息 您可以通过向商政通付款的方式获取商政通相关的产品及虚拟产品。上述付款服务可能由商政通以外的第三方提供，商政通并不会获取与您付款相关的密码等信息。但可能从第三方支付平台获得您的第三方支付信息（支付宝支付、微信支付）。若您希望了解第三方服务提供商收集个人信息的具体规则，请查阅您选择的第三方付款服务提供商的隐私政策。</p>

    <h3><strong>1.4 您充分知晓，在以下情形下，我们收集、使用个人信息无需征得您的授权同意：</strong></h3>

    <p>1.4.1 与国家安全、国防安全有关的；</p>
    <p>1.4.2 与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>1.4.3 与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p>1.4.4 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p>1.4.5 所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
    <p>1.4.6 从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
    <p>1.4.7 根据您要求签订和履行合同所必需的；</p>
    <p>1.4.8 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
    <p>1.4.9 法律法规规定的其他情形。</p>
    <p>1.5 根据法律规定，向第三方提供经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</p>

    <h2>第二章 如何使用您的个人信息</h2>

    <p>在收集您的个人信息后，商政通将根据如下规则使用您的个人信息：</p>
    <p>2.1 会根据本《隐私政策》的约定并为实现商政通的产品或服务功能对所收集的个人信息进行使用。</p>
    <p>2.2 请您注意，对于您在使用商政通的产品或服务时所提供的所有个人信息，除非您删除或通过相关设置拒绝我们收集，否则您将在使用产品或服务期间、账号注销前持续授权我们使用。但在您注销账号时，商政通将停止使用并删除您的个人信息。</p>
    <p>2.3 <strong><u>商政通会对产品或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，</u></strong>以展示维普期刊产品或服务的整体使用趋势、及时修复产品在使用过程产生的故障及推送关于产品的最新咨询、功能。<strong><u>但这些统计信息不会包含您的任何身份识别信息。</u></strong></p>
    <p>2.4 <strong><u>当商政通要将您的个人信息用于本政策未载明的其它用途时，或将基于特定目的收集而来的信息用于其他目的时，商政通会主动征求您的同意。</u></strong></p>

    <h2>第三章 如何共享、转让、公开披露您的个人信息</h2>

    <h3>共享</h3>

    <p>3.1 <strong><u>商政通将严格遵守相关法律法规，对您的个人信息即账号信息、昵称信息、检索记录、浏览记录、付费信息、订单信息、第三方支付信息（包含支付宝支付、微信支付）、手机号码、行业领域、用户身份、机构信息（下同），予以保密。除以下情况外，我们不会向其他人共享您的相关信息：</u></strong></p>
    <p>3.1.1 事先获得您明确的同意或授权；</p>
    <p>3.1.2 根据适用的法律法规规定，或基于司法或行政主管部门的强制性要求进行提供；</p>
    <p>3.1.3 在法律法规允许的范围内，为维护您或其他商政通用户或其他个人的生命、财产等合法权益或是社会公共利益而有必要提供；</p>
    <p>3.1.4 应您的监护人的合法要求而提供您的信息；</p>
    <p>3.1.5 根据与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定而提供；</p>

    <h3>转让</h3>

    <p>3.2 商政通不会将您的<strong><u>账号信息、昵称信息、检索记录、浏览记录、付费信息、订单信息、第三方支付信息（包含支付宝支付、微信支付）、手机号码、行业领域、用户身份、机构信息（下同）</u></strong>转让给任何公司、组织和个人，但以下情况除外：</p>
    <p>3.2.1 事先获得您明确的同意或授权；</p>
    <p>3.2.2 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求而必须进行提供；</p>
    <p>3.2.3 根据与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定而提供；</p>
    <p>3.2.4 在涉及收购、兼并、破产清算、重组等重大变更时，如涉及到个人信息转让的，商政通会要求新的持有您个人信息的公司或组织继续履行本《隐私政策》项下的责任和义务。如变更后的主体需变更个人信息使用目的，我们会要求其事先获得您的明示同意。</p>

    <h3>公开披露</h3>

    <p>3.3 商政通仅会在以下情况下，且在采取符合业界标准的安全防护措施的前提下，才会公开披露您的<strong><u>账号信息、昵称信息、检索记录、浏览记录、付费信息、订单信息、第三方支付信息（包含支付宝支付、微信支付）、手机号码、行业领域、用户身份、机构信息（下同）：</u></strong></p>
    <p>3.3.1 根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
    <p>3.3.2 根据法律、法规的要求、行政或司法机关的强制性要求，我们可能会公开披露您的个人信息。当商政通收到上述披露请求时，商政通会依法要求请求方出具相关法律文件，如传票或协助调查函等。商政通会慎重审查每一个披露请求，以确保该等披露请求符合相关法律规定。在法律法规许可的前提下，商政通会对包含披露信息的文件采取加密保护等措施。</p>

    <h2>第四章 如何保护您的个人信息</h2>

    <p>4.1 商政通非常重视个人信息安全，并会采取一切合理可行的措施，持续保护您的个人信息，以防其他人在未经授权的情况下访问、篡改或披露商政通收集的您的个人信息。</p>
    <p>4.2 关于账号、手机号、密码传输过程的防篡改，<strong><u>我们全程对个人信息执行加密传输，用户密码保密输入，加密入数据库 ，手机号码隐藏中间四位。</u></strong></p>
    <p>4.3 我们努力为用户的信息安全提供保障，以防止信息的丢失、不当使用、未经授权访问或披露。</p>
    <p>4.4 我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，<strong><u>我们会使用加密技术（例如，SSL /TLS）、匿名化处理等手段来保护你的个人信息。</u></strong></p>
    <p>4.5 我们建立专门的管理制度、流程和组织以保障信息的安全。我们通过不断提升的技术手段加强安装在你设备端的软件的安全能力，以防止你的个人信息泄露。例如，我们为了安全传输会在你设备本地完成部分信息加密的工作；<strong><u>为了预防诈骗、盗号、仿冒他人等不法行为和进行安全检查我们可能会分析利用唯一设备标识符、登录IP地址、操作日志、地理位置信息数据，以便于采取安全措施或对用户进行安全提醒。</u></strong></p>
    <p>4.6 若发生个人信息泄露等安全事件，我们会依法启动应急预案，阻止安全事件扩大，并以推送通知、公告形式告知你安全事件的情况、事件可能对你的影响以及我们将采取的补救措施。我们还将按照法律法规和监管部门要求，上报个人信息安全事件的处置情况。</p>
    <p>4.7 目前，<strong><u>商政通在信息安全与网络安全方面已达到ISO 19001、网络安全等级保护（二级）系等国际及国内权威认证标准的要求，并已获得了相应的认证。</u></strong></p>
    <p>我们会尽力保护你的个人信息。我们也请你理解，任何安全措施都无法做到无懈可击。</p>

    <h2>第五章 如何保存您的个人信息</h2>

    <h3>保存期限</h3>

    <p>5.1 在用户使用商政通产品或服务期间，商政通会持续保存用户的个人信息。</p>
    <p>5.2 商政通承诺始终按照法律的规定在合理必要期限内在存储您个人信息，对于日志信息、记录备份等信息为您注销账号后180天（但根据法律规定有最短保存期限要求的，则我们会保存法律要求的最短期限）；交易信息为交易完成日起三年或您注销账号后180天（以较长者为准）。<strong><u>在超出上述期限后，我们会对您的相关信息进行删除或匿名化处理。</u></strong></p>

    <h3>保存地域</h3>

    <p>5.3 <strong><u>您的个人信息将全部被存储于中华人民共和国境内。</u></strong></p>
    <p>5.4 目前我们不存在向境外提供个人信息的场景。</p>

    <h2>第六章 关于cookies的使用</h2>

    <p>6.1 在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies ，以便您能登录或使用依赖于cookies的本应用平台服务或功能。本应用使用cookies可为您提供更加周到的个性化服务，包括推广服务。</p>
    <p>6.2您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。</p>
    <p>6.3 通过本应用所设cookies所取得的有关信息，将适用本政策。</p>

    <h2>第七章 管理、查看或删除您的个人信息</h2>

    <p>商政通非常尊重您对自己的个人信息所享有的权利。我们保障您对个人信息所享有的访问、更正、删除、管理等权利。</p>

    <h3>访问和更正您的个人信息</h3>

    <p>7.1 除法律法规另有规定之外，您有权行使数据访问权。当您发现我们处理关于您的个人信息有错误或者您有其他修改、补充需求时，您也有权要求我们或自行予以更正。您行使数据访问和更正权的方式包括但不限于：</p>
    <p>7.1.1如果您希望访问或修改您在商政通产品或服务中的账号信息，包括绑定机构、手机号、邮箱，您可以通过访问我们的产品进行操作（可通过点击“我的”，进入个人主页后，点击“设置”功能后进行操作）。</p>
    <p>7.2 您有权知悉通过商政通获得您的个人信息的第三方的身份或类型。您可以通过本政策第一章和第四章了解第三方的身份或类型。</p>

    <h3>删除您的个人信息及撤回已同意的授权</h3>

    <p>7.3 在以下情形中，您可以向商政通提出删除个人信息的请求：</p>
    <p>7.3.1 如果商政通处理个人信息的行为违反相关的法律法规；</p>
    <p>7.3.2 如果商政通收集、使用您的个人信息，却未征得您的同意；</p>
    <p>7.3.3 如果商政通处理个人信息的行为违反了与您的约定或法律的规定；</p>
    <p>7.3.4 如果您不再使用商政通的产品或服务，或者您注销了相关账号；</p>
    <p>7.3.5 如果商政通不再为您提供产品或服务。</p>
    <p>7.4 您有权向商政通撤回您此前做出的有关同意收集、使用您的个人信息的授权。当您撤回同意后，我们将不再处理您的相关个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理活动。</p>
    <p>7.5 您可以通过删除相关个人信息的方式撤回您此前就特定个人信息而对我们做出的同意授权。</p>

    <h3><u>注销账号</u></h3>

    <p>7.6 您有权随时注销您在商政通产品或服务中注册的账号，主要包含两种以下两种方式：</p>
    <p>7.6.1为保障账号及财产安全，您需要通过客户服务或本《隐私条款》第九章载明的联系方式向商政通提出您的账号注销请求。商政通将在与您核实相关信息后对您的请求做出响应。</p>
    <p>7.6.2 您也可以<strong><u>通过商政通来手动注销账号信息（可通过点击“个人中心”，进入个人主页后，点击“注销账户”功能），</u></strong>需要说明的是，注销页面会详细说明注销产生的影响和注销操作指引，<strong><u>账号注销后，您的账号将无法登录将放弃以下权益和资产：</u></strong></p>
    <p><strong><u>①.浏览及下载记录将无法查询</u></strong></p>
    <p><strong><u>②.账号将会无法登录</u></strong></p>
    <p><strong><u>③.放弃内容更新提示服务及检索式订阅服务</u></strong></p>
    <p><strong><u>④.放弃优惠券和折扣权益</u></strong></p>
    <p><strong><u>⑤.放弃余额金额和会员权益</u></strong></p>
    <p><strong><u>⑥.账号信息将连同APP一并注销</u></strong></p>
    <p><strong><u>请确保所有订单已完结并且无纠纷</u></strong></p>
    <p>7.7 <strong><u>请您注意，注销商政通相关产品或服务账号是不可恢复的操作。</u></strong>在注销账号之后，我们将停止为您提供产品或服务，并将删除该账号项下的您的个人信息，除非法律法规另有规定。</p>

    <h2>第八章 本政策如何更新</h2>

    <p>8.1 如商政通产品或服务发生以下变化，商政通将及时对本《隐私政策》进行相应的修订：</p>
    <p>8.1.1 <strong><u>商政通产品或服务所涉业务功能发生变更，导致处理个人信息的目的、类型、使用方式发生变更；</u></strong></p>
    <p>8.1.2 <strong><u>您参与个人信息处理方面的权利及其行使方式发生重大变化；</u></strong></p>
    <p>8.1.3 商政通负责处理您的个人信息安全的部门的联络方式发生变更；</p>
    <p>8.1.4 发生其他可能影响用户个人信息安全或影响用户隐私权利的变更等。</p>
    <p>8.2《隐私政策》修订后商政通会在商政通产品或服务相关界面发布最新版本并以弹窗、推送通知等合理的方式告知用户，以便用户及时了解《隐私政策》的最新版本。</p>
    <p>8.3 未经您的明确同意，商政通不会削减您基于本《隐私政策》所享有的权利。</p>
    <p>8.4 如无特殊说明，修订后的《隐私政策》自公布之日起生效。</p>

    <h2>第九章 投诉及建议</h2>

    <p>9.1 您在使用商政通产品或服务的过程中，如果对本《隐私政策》有任何的疑义或建议，或您认为个人信息没有得到本《隐私政策》规定的保护，您可以通过以下方式联系我们，我们将真诚地处理您的投诉及建议。</p>

    <address>
      公司名称：重庆维普资讯有限公司<br>
      联系邮箱：jubao@cqvip.com<br>
      联系电话： 023-67882612（9:00-17:00）<br>
      地 址：重庆市北部新区洪湖西路18号重庆维普资讯有限公司
    </address>
  </div>
</template>
<style lang="scss" scoped>
.protocol {
  padding: 20px 40px;
  line-height: 2;
  color: #000;
}

h1 {
  margin: 10px 0;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

h2 {
  margin: 10px 0;
  font-size: 18px;
}

h3 {
  margin: 10px 0;
  font-size: 16px;
}

p {
  margin: 0.8em 0;
  font-size: 14px;
  text-align: justify;
}

a {
  text-decoration: underline;
}

time {
  display: block;
  margin: 20px 0;
  font-size: 12px;
  text-align: center;
  > span {
    display: inline-block;
    margin: 0 20px;
  }
}
address {
  margin-top: 50px;
  font-style: normal;
}
</style>
<script>


export default {

}
</script>