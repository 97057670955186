import { Recordlog } from "@/utils/api";
export default class BehaviorLog {
    // constructor(kind, routerName,
    //     accessAddress, accessTime
    //     , userId, organId
    //     ,ipAddress,sourceAddress,
    //     operatingSystem,browserVersion,
    //     attachmentName,operationTime
    //     ,detailId,searchKey
    //     ,retrievalType,searchCriteria,
    //     pageId,buttonID,
    //     noteAnalysis,ClusterAnalysis) {
        constructor(param) {
        this.kind =param. kind;
        this.routerName = param.routerName;
        this.accessAddress =param. accessAddress;
        this.accessTime =param. accessTime;
        this.userId =param. userId;
        this.organId =param. organId;

        this.ipAddress =param. ipAddress;
        this.sourceAddress =param. sourceAddress;
        this.operatingSystem =param. operatingSystem;
        this.browserVersion =param. browserVersion;
        this.attachmentName = param.attachmentName;
        this.operationTime =param. operationTime;
        this.detailId =param. detailId;
        this.retrievalType = param.retrievalType;
        this.searchKey =param. searchKey;
        this.searchCriteria =param.searchCriteria;
        this.pageId =param. pageId;
        this.buttonID =param. buttonID;
        this.noteAnalysis =param. noteAnalysis;
        this.clusterAnalysis =param. clusterAnalysis;
        this.siteId=0;
    }
    ///访问日志
     AccessLog() {
        console.log(this);
        Recordlog(this).then(() => {

        }).catch(() => {

        });
    }
    //附件下载日志
    AttachmentDownloadLog() {

    }
}