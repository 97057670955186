function dispatchEventStroage () {
  const signSetItem = localStorage.setItem
  localStorage.setItem = function (key, val) {
      let setEvent = new Event('setItemEvent')
      setEvent.key = key
      setEvent.newValue = val
      window.dispatchEvent(setEvent)
      signSetItem.apply(this, arguments)
  }
}

import numeral from "numeral";

export function numberFormatter(value, format) {
  return numeral(value).format(format);
}


/**json字符串转对象 根据key获取值 */
export function parseJsonAndGetKey(jsonString, key) {
  try {
    const obj = JSON.parse(jsonString);
    return obj[key];
  } catch (error) {
    console.error('Error parsing JSON or getting key:', error);
    return null;
  }
}

/** son字符串转对象*/
export function parseJson(jsonString) {
  try {
    const obj = JSON.parse(jsonString);
    return obj;
  } catch (error) {
    console.error('Error parsing JSON', jsonString);
    return null;
  }
}

/**获取集合中某个key数据 */
export function getArryByKey(arr,key) {
  // 使用 map 方法提取指定 key 的值
  return arr.map((item) => item[key]);
}

/**获取集合指定对象中某个key */
export function foundExponentials(arr,val,key1,key2) {
  // 使用数组的find方法来查找特定对象
  let item = arr.find((item) => item[key1] === val);
  if (item == null) {
    return "";
  }
  return item[key2]||"";
}


export default dispatchEventStroage