
import crypto from './crypto';

let _common = {}

_common = {
  setStorage: SetStorage,
  getStorage: GetStorage,
  filterHtml: FilterHtml,
  delStorage: DelStorage
}

export default _common

export function SetStorage(name, datas, expired = 1440, encrypt = true) {
  if (expired) {
    let json = { data: datas, expired: Date.now() + 1000 * 60 * expired };
    if (encrypt) {
      localStorage.setItem(name, crypto.encrypt(JSON.stringify(json)));
    } else {
      localStorage.setItem(name, JSON.stringify(json));
    }
  } else {
    let json = { data: datas, expired: Date.now() + 1000 * 60 * 1440 };
    if (encrypt) {
      localStorage.setItem(name, crypto.encrypt(JSON.stringify(json)));
    } else {
      localStorage.setItem(name, JSON.stringify(json));
    }

  }
}

export function GetStorage(name, encrypt = true) {
  const now = Date.now();
  let jsonStr = localStorage.getItem(name);
  if (jsonStr == null || jsonStr== 'undefined' || jsonStr.length <= 0) {
    return null;
  }
  if (encrypt) {
    jsonStr = crypto.decrypt(jsonStr);
  }
  let json = JSON.parse(jsonStr);
  if (json) {
    if (now >= json.expired) {
      localStorage.removeItem(name);
      return null;
    } else {
      return json.data;
    }
  } else {
    return null;
  }
}

export function FilterHtml(str, len) {
  if (str) {
    const reg = /<[^<>]+>/g;
    if (str.replace(reg, '').length > len) {
      return str.replace(reg, '').slice(0, len) + '...';
    } else {
      return str.replace(reg, '');
    }

  } else {
    return '';
  }
}

export function DelStorage(key)
{
  let value = localStorage.getItem(key);
  if(value!=undefined)
  {
     localStorage.removeItem(key);
  }
}

// 日期格式化
export function parseTime(time, pattern) {
  if(!time)
  {
    return '';
  }
  time=time.replace("T"," ");
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{M}-{d} {h}:{m}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
    w: date.getDay()
  }
  const time_str = format.replace(/{(y|M|d|h|m|s|w)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'w') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * 计算两个时间之间相差的天数——入参是new Date(‘2023-01-13 10:14:30’)这样的格式
 * @param {date} date_1 - 较大的日期
 * @param {date} date_2 - 较小的日期
 * @returns {number} - 日期相差天数
 */
export function getDiffDay(date_1, date_2){
  let totalDays, diffDate
  let myDate_1 = Date.parse(date_1)
  let myDate_2 = Date.parse(date_2)
  diffDate = Math.abs(myDate_1 - myDate_2) 
  totalDays = Math.floor(diffDate / (1000 * 3600 * 24))
  return totalDays 
}
