// 刚刚封装的axios
import request from './http';


/**
 * 机构自动补全
 * @param {*} data 
 * @returns 
 */
export const autoorgan = (data) => {
    return request({
        url: '/orderreadnew/autoorgan',
        method: 'post',
        data: data
    })
}

/**
 * 检索订阅列表数据
 * @param {*} data 
 * @returns 
 */
export const orderreadsearch = (data) => {
    return request({
        url: '/orderreadnew/search',
        method: 'post',
        data: data
    })
}

/**
 * 查询订阅列表(无需登录)
 * @param {*} data 
 * @returns 
 */
export const getorderreadnewlistnologin = (data) => {
    return request({
        url: '/orderreadnew/no/list',
        method: 'get',
        params: data
    })
}

/**
 * 查询订阅列表(需要登录)
 * @param {*} data 
 * @returns 
 */
export const getorderreadnewlist = (data) => {
    return request({
        url: '/orderreadnew/list',
        method: 'get',
        params: data
    })
}

/**
 * 查询单个订阅
 * @param {*} data 
 * @returns 
 */
export const getorderreadnewdetail = (data) => {
    return request({
        url: '/orderreadnew/detail/' + data,
        method: 'get',
    })
}

/**
 * 新增/编辑订阅列表
 * @param {*} data 
 * @returns 
 */
export const editorderreadnew = (data) => {
    return request({
        url: '/orderreadnew/edit',
        method: 'post',
        data: data
    })
}

/**
 * 删除订阅列表
 * @param {*} data 
 * @returns 
 */
export const deleteorderreadnew = (data) => {
    return request({
        url: '/orderreadnew/delete/' + data,
        method: 'delete',
    })
}

/**
 * 首页订阅提醒
 * @param {*} data 
 * @returns 
 */
export const remindgetorderreadnewlist = (data) => {
    return request({
        url: '/orderreadnew/remind',
        method: 'get',
        params: data
    })
}

/**
 * 获取推荐订阅
 * @param {*} data 
 * @returns 
 */
export const recommendorderreadnewlist = (data) => {
    return request({
        url: '/orderreadnew/recommend',
        method: 'get',
        params: data
    })
}
//
/**
 * 获取政策动态
 * @param {*} data 
 * @returns 
 */
export const orderdynamiclist = () => {
    return request({
        url: '/orderreadnew/dynamiclist',
        method: 'get',
    })
}

/**
 * 一键订阅
 * @param {*} data 
 * @returns 
 */
export const orderorderreadnewlist = (data) => {
    return request({
        url: '/orderreadnew/order/' + data,
        method: 'post',
    })
}

/**
 * 根据orderReadId获取规则下所有文章id
 * @param {*} data 
 * @returns 
 */
export const getidsbyorderreadid = (data) => {
    return request({
        url: '/orderreadnew/getids/' + data,
        method: 'get',
    })
}

/**
 * 获取订阅邮箱
 * @param {*} data 
 * @returns 
 */
export const getemail = (data) => {
    return request({
        url: '/orderreadnew/getemail',
        method: 'get',
        params: data
    })
}