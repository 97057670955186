<script type="text/javascript">
    // 定义一些公共的属性和方法
    let isDetail = 1
    function commonFun() {
        console.log("公共方法")
    }
    // 暴露出这些属性和方法
    export default {
        isDetail,
        commonFun
    }
</script>