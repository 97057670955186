import CryptoJS from 'crypto-js';

const IV = '0000000000000000';
const defaultKey = '7E79g12u342f8e61';

export default ({
    /**
     * AES加密-CBC-PKCS7
     * @param value 待加密字符串
     * @param keyStr 密钥
     */
    encrypt(value, keyStr) {
        let key = CryptoJS.enc.Utf8.parse(defaultKey);
        if (keyStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
        }
        let iv = CryptoJS.enc.Utf8.parse(IV);
        let encrypted = CryptoJS.AES.encrypt(value, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });

        return encrypted.toString();
    },
    /**
     * AES解密-CBC-PKCS7
     * @param value 待解密字符串
     * @param keyStr 密钥
     */
    decrypt(value, keyStr) {
        let key = CryptoJS.enc.Utf8.parse(defaultKey);
        if (keyStr) {
            key = CryptoJS.enc.Utf8.parse(keyStr);
        }
        let iv = CryptoJS.enc.Utf8.parse(IV);
        let decrypted = CryptoJS.AES.decrypt(value, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        // 转换为 utf8 字符串
        decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
        return decrypted.toString();
    },
});