import axios from 'axios';
import Vue from 'vue';
import router from '../router'
import { redirectSSOLogin } from "@/utils/sso-login-utils";
import { saveAs } from 'file-saver'
import { MessageBox, Message, Loading } from 'element-ui';

//console.log(process.env.VUE_APP_BASE_API);
//  让请求在浏览器中允许跨域携带cookie
axios.defaults.withCredentials = true;

// 使用自定义配置新建一个 axios 实例
const service = axios.create({
  // 基础的请求地址
  //baseURL: 'http://192.168.20.27:8084/api',
  //baseURL: 'http://192.168.30.115:8085/api',
  // baseURL: 'http://192.168.0.47:8081/api',
 // baseURL: 'http://192.168.0.47:5000/api',
  //baseURL: 'http://policy.test.cqvip.com:8090/api',
  // baseURL: 'https://zcapi.vipisall.com/api',
  // 设置超时时间 5s
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000
});


// //添加超时后的处理（axios中需要你根据error信息来进行判断）
// axios().catch(error => {
//   const { message } = error;
//   if (error.code === 'ECONNABORTED' && message.indexOf('timeout')> -1){
//     // 超时处理，可以直接弹出错误或者重新发起一次请求
//     this.$MessageBox.MessageError('请求超时！请检查网络问题')
//   }
//     // 若不是超时,则返回未错误信息
//     return Promise.reject(error);
// })
let _tokenKey = 'access-token';
let _refreshTokenKey = 'x-access-token';



// 请求拦截器，例如请求的时候在头部加上请求的token
service.interceptors.request.use(config => {
  //  if (localStorage.getItem('token')) {
  //     config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
  //  }
  //debugger
  let token = localStorage.getItem("_tokenKey");
  let refreshToken = localStorage.getItem("_refreshTokenKey");
  // 判断是否进行了登录
  if (token != null && refreshToken != null) {
    config.headers['Authorization'] = 'Bearer ' + token;
    config.headers['X-Authorization'] = 'Bearer ' + refreshToken;
  }
  return config;  //  有且必须有一个config对象被返回
}, error => {
  //   对请求错误做些什么
  console.log(error);
  //console.log(78979);
  return Promise.reject();
});

// 响应拦截器,例如判断服务器返回的状态，400，500啥的，其实超时可以写到这里面来，我分开写了一个比较直观
service.interceptors.response.use(
  response => {
    // 二进制数据则直接返回
    if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
      return response;
    }
    else if (response.status === 200) {
      // 服务器返回的headers

      let headers = response.headers;
      let token = headers[_tokenKey];
      let refreshToken = headers[_refreshTokenKey];
      // 判断是否有返回的token(登录和自动刷新都在headers中设置了token)
      if (token != null && refreshToken != null) {
        //console.log('存储token');
        //debugger;
        //存到localStorage,发送请求时取出
        localStorage.setItem("_tokenKey", token);
        localStorage.setItem("_refreshTokenKey", refreshToken);
      }
      return Promise.resolve(response.data);
    }
    else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况,这些自定义（需要与后台商量返回）
  error => {
    if (error.response.status == 401) {
      //Vue.prototype.$MessageBox.MessageInfo('用户信息过期，请重新登陆!')
      // 清除token
      localStorage.removeItem("token");
      // 跳转登录
      // setTimeout(() => {
      //   //清除缓存信息
      //   localStorage.removeItem("_user")
      //   localStorage.removeItem("_tokenKey")
      //   localStorage.removeItem("_refreshTokenKey")
      //   localStorage.removeItem("_MKA")
      //   localStorage.removeItem("_UIF")
      //   console.log(router.currentRoute);
      //   // router.push({
      //   //   path: '/User/Login',
      //   //   // query: { redirect: router.currentRoute.fullPath } //从哪个页面跳转
      //   // })
      //   redirectSSOLogin();
      // }, 1000);
      localStorage.removeItem("_user")
      localStorage.removeItem("_tokenKey")
      localStorage.removeItem("_refreshTokenKey")
      localStorage.removeItem("_MKA")
      localStorage.removeItem("_UIF")
      //console.log(router.currentRoute);
      // router.push({
      //   path: '/User/Login',
      //   // query: { redirect: router.currentRoute.fullPath } //从哪个页面跳转
      // })
      //debugger
      redirectSSOLogin();
    } else {
      if (error.response.status >= 500) {
        Vue.prototype.$MessageBox.MessageError('服务器开小差了，请稍后再试~')
      } else {
        Vue.prototype.$MessageBox.MessageError('服务器开小差了，请稍后再试！')
        return Promise.reject(error)
      }
    }
  }

);
let downloadLoadingInstance;
/**
 * 全文下载
 * @returns 
 */
export async function downFile(url, params, config,fileName) {
  downloadLoadingInstance = Loading.service({ text: '正在下载数据，请稍候', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
  service.get(url, {
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config,
  })
    .then(async (resp) => {
      console.log('resp',resp);
      var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*');
      var contentDisposition = decodeURI(resp.headers['content-disposition']);
      console.log('contentDisposition',contentDisposition);
      var result = patt.exec(contentDisposition);
      //console.log(result);
      //var fileName = result[1];
      //fileName = fileName.replace(/\"/g, '')

      if(fileName=='' || fileName==undefined)
        fileName= new Date().getTime();
      const blob = new Blob([resp.data]);
      saveAs(blob,  fileName+ '.pdf');
      downloadLoadingInstance.close();
    })
    .catch((r) => {
      console.log(r);
      Message.error('下载文件出现错误，请联系管理员！');
      downloadLoadingInstance.close();
    })
}
export default service;

