<template>
  <el-dialog
    :append-to-body="true"
    title="提示"
    :visible.sync="bindVisible"
    width="400px"
  >
    <div class="bind-content" :visible="bindVisible">
      <!-- <p>您正在使用个性化功能，请先登录</p> -->
      您正在使用个性化功能，请先登录<el-button @click="redirectLogin" type="text"
        >点击登录</el-button
      >
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="bindVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<style lang="scss" scoped>
@import "@/assets/css/var.scss";
.bind-content {
  font-size: 15px;
  line-height: 30px;
  .el-button--text {
    margin: 0 5px;
    color: $colorMain;
    font-size: 15px;
    text-decoration: underline;
  }
}
</style>
<script>
import { redirectSSOLogin } from "@/utils/sso-login-utils";
export default {
  name: "bindaltOrg",
  data() {
    return {
      bindVisible: false,
    };
  },
  methods: {
    init() {
      this.bindVisible = true;
    },
    redirectLogin() {
      redirectSSOLogin();
    },
  },
};
</script>
