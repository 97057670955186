<template>
  <div class="protocol">
    <h1>用户服务协议</h1>
    <time><span>更新日期：2022年7月25日</span><span>生效日期：2020年7月25日</span></time>
    <h2>引言</h2>
    <p>欢迎您使用商政通产品及相关服务！本服务协议双方为重庆维普资讯有限公司（以下简称“维普资讯”）与维普资讯用户/读者，本服务协议具有合同效力。</p>
    <p>商政通产品及相关服务，<strong><u>系指公司通过合法拥有并运营的、标注名称为商政通（https://zc.vipisall.com）的网站以及APP客户端、小程序等向您提供的产品与服务，包括但不限于搜索查询、阅读全文、专题订阅、个性化推荐、发布信息等核心功能以及其他功能。</u></strong>《商政通用户协议》（以下简称“本协议”）是您与公司之间就您注册、登录、使用（以下统称“使用”）商政通产品及相关服务所订立的协议。</p>
    <p>为了更好地为您提供服务，请您在开始使用商政通产品及相关服务之前，认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。<strong><u>其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。如您不同意本协议，这将导致我们无法为您提供完整的产品和服务，您也可以选择停止使用。如您自主选择同意或使用商政通产品及相关服务，则视为您已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与商政通产品及相关服务相关的协议和规则（包括但不限于《隐私政策》）的约束。</u></strong></p>
    
    <p>1. 重庆维普资讯有限公司（以下简称“维普资讯”）是一家专业文献资源数据服务商，同时面向国内外组织和个人提供信息增值服务和知识服务解决方案。为了使维普资讯旗下网站及产品依法依规运营，并为用户提供更安全、优质的服务内容，维普资讯同用户之间缔结本协议并共同遵守。</p>
    <p>2. 维普资讯可对本协议随时进行更改，并在其网站上公布更改内容，公布之日起即生效。如果您继续使用维普资讯的相关服务，则视为接受协议条款的变更；否则您须立即停止接受服务或提出停止服务的要求以便终止服务。</p>
    <p>3. 您注册成为用户后即视为缔结本协议，您违反协议的行为可能导致法律上的纠纷，维普资讯保留追诉的权利。</p>
    <p>3. 您注册成为用户后即视为缔结本协议，您违反协议的行为可能导致法律上的纠纷，维普资讯保留追诉的权利。</p>

    <h3>第一章 商政通产品及相关服务</h3>
    <p>1.1 为更好的提升用户体验及服务，公司将不定期提供商政通产品及相关服务的更新或改变（包括但不限于产品修改、升级、功能强化、开发新服务、产品替换等）。<strong><u>为保证商政通产品及相关服务安全、提升用户服务，在商政通产品及相关服务的部分或全部更新后，公司将在可行的情况下以妥当的方式（包括但不限于系统提示、公告、站内信等）提示您，您有权选择接受更新后的版本；如您选择不作更新，商政通产品及相关服务的部分功能将受到限制或不能正常使用。</u></strong></p>
    <p>1.2 除非得到公司事先书面授权，您不得以任何形式对商政通产品及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。</p>
    <p>1.3 <strong><u>您无需注册也可开始使用商政通产品及相关服务，但部分功能或服务可能会受到影响。</u></strong>同时，您也理解，为使您更好地使用商政通产品及相关服务，保障您的账号安全，某些功能和/或某些单项服务项目将要求您按照国家相关法律法规的规定，提供真实的身份信息实名注册并登录后方可使用。</p>

    <h3>第二章 关于“账号”</h3>

    <p>2.1 <strong><u>商政通产品及相关服务为您提供了注册通道，您有权选择合法且符合站点要求的字符组合作为自己的账号，并自行设置符合安全要求的密码。用户设置的账号、密码是用户用以登录并使用商政通产品及相关服务的凭证。</u></strong></p>
    <p>2.2 您理解并承诺，您所设置的账号不得违反国家法律法规及公司的相关规则，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为，您的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设账号，不得恶意注册商政通账号（包括但不限于频繁注册、批量注册账号等行为）。公司有权对您提交的信息进行审核。</p>
    <p>2.3 <strong><u>您的账号仅限于您本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。</u></strong>如果公司发现或者有合理理由认为使用者并非账号初始注册人，为保障账号安全，公司有权立即暂停或终止向该注册账号提供服务，或注销该账号。</p>
    <p>2.4 <strong><u>您有责任维护个人账号、密码的安全性与保密性，并对您以注册账号名义所从事的活动承担全部法律责任，包括但不限于您在商政通产品及相关服务上进行的任何数据修改、言论发表、款项支付等操作行为可能引起的一切法律责任。</u></strong>您应高度重视对账号与密码的保密，在任何情况下不向他人透露账号及密码。若发现他人未经许可使用您的账号或发生其他任何安全漏洞问题时，您应当立即通知公司。</p>
    <p>2.5 <strong><u>在丢失账号或遗忘密码时，您可遵照公司的申诉途径及时申诉请求找回账号或密码。您理解并认可，公司的密码找回机制仅需申诉流程上所填资料与系统记录资料具有一致性，而无法识别申诉人是否系真正账号有权使用者。</u></strong>公司特别提醒您应妥善保管您的账号和密码。当您使用完毕后，应安全退出。如因您保管不当等自身原因或其他不可抗因素导致遭受盗号或密码丢失，您将自行承担相应责任。</p>
    <p>2.6 <strong><u>在注册、使用和管理账号时，您应保证注册账号时填写的身份信息的真实性，请您在注册、管理账号时使用真实、准确、合法、有效的相关身份证明材料及必要信息（包括您的姓名及电子邮件地址、联系电话）。</u></strong>依照国家相关法律法规的规定，为使用商政通产品及相关服务的部分功能，您需要填写真实的身份信息，请您按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若您提交的材料或提供的信息不规范或不符合要求的，则公司有权拒绝为您提供相关功能，您可能无法使用商政通产品及相关服务或在使用过程中部分功能受到限制。</p>
    <p>2.7 <strong><u>除自行注册商政通账号外，用户也可授权使用其合法拥有的包括但不限于机构或其关联机构用户账号，以及实名注册的第三方产品用户账号登录使用商政通产品及相关服务，但第三方产品或平台对此有限制或禁止的除外。</u></strong>当用户以前述已有账号登录使用的，应保证相应账号已进行实名注册登记，并同样适用本协议中的相关条款。</p>
    <p>2.8 <strong><u>您理解并同意，除您登录、使用商政通产品及相关服务外，您还可以用商政通账号登录使用公司及其关联公司提供的其他产品、服务。</u></strong>您以商政通账号登录并使用前述服务时，将受到前述服务的实际提供方的《用户协议》及其他协议条款约束。</p>

    <h3>第三章 用户个人信息保护</h3>
    <p>公司与您一同致力于您个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，保护用户个人信息是公司的基本原则之一。在使用商政通产品及相关服务的过程中，您可能需要提供您的个人信息（包括但不限于姓名、电话号码、邮箱等），以便公司向您提供更好的服务和相应的技术支持。公司将运用加密技术、匿名化处理等其他与商政通产品及相关服务相匹配的技术措施及其他安全措施保护您的个人信息，更多关于用户个人信息保护的内容，请参见《隐私政策》。</p>
    <h3>第四章 用户行为规范</h3>
    <p>4.1 用户行为要求</p>
    <p>您应当对您使用本产品及相关服务的行为负责，除非法律允许或者经公司事先书面许可，您使用商政通产品及相关服务不得具有下列行为：</p>
    <p>4.1.1 <strong><u>使用未经公司授权或许可的任何插件、外挂、系统或第三方工具对商政通产品及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。</u></strong></p>
    <p>4.1.2 利用或针对商政通产品及相关服务进行任何危害计算机网络安全的行为，包括但不限于：</p>
    <p>（1）<strong><u>非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；</u></strong></p>
    <p>（2）<strong><u>提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</u></strong></p>
    <p>（3）<strong><u>明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；</u></strong></p>
    <p>（4）<strong><u>使用未经许可的数据或进入未经许可的服务器/账号；</u></strong></p>
    <p>（5）<strong><u>未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</u></strong></p>
    <p>（6）<strong><u>未经许可，企图探查、扫描、测试商政通系统或网络的弱点或其它实施破坏网络安全的行为；</u></strong></p>
    <p>（7）<strong><u>企图干涉、破坏商政通系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</u></strong></p>
    <p>（8）<strong><u>伪造TCP/IP数据包名称或部分名称；</u></strong></p>
    <p>（9）<strong><u>对商政通产品及相关服务进行反向工程、反向汇编、编译或者以其他方式尝试发现本产品的源代码；</u></strong></p>
    <p>（10）<strong><u>恶意注册商政通产品及相关服务的账号，包括但不限于频繁、批量注册账号；</u></strong></p>
    <p>（11）<strong><u>违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。</u></strong></p>
    <p>4.2 如果公司有理由认为您的行为违反或可能违反上述约定的，公司可独立进行判断并处理，且有权在不事先通知的情况下终止向您提供服务，并追究相关法律责任。</p>


    <h3>第五章 商政通信息内容的使用规范</h3>
    <p>5.1 未经公司书面许可，您不得自行或授权、允许、协助任何第三人对本协议商政通产品及相关服务中信息内容进行如下行为：</p>
    <p>（1）复制、读取、采用商政通产品及相关服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；</p>
    <p>（2）擅自编辑、整理、编排商政通产品及相关服务的信息内容后在商政通产品及相关服务的源页面以外的渠道进行展示；</p>
    <p>（3）采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对商政通产品及相关服务的信息内容产生流量、阅读量引导、转移、劫持等不利影响；</p>
    <p>（4）其他非法获取或使用商政通产品及相关服务的信息内容的行为。</p>
    <p>5.2 经公司书面许可后，您对商政通产品及相关服务的信息内容的分享、转发等行为，还应符合以下规范：</p>
    <p>（1）<strong><u>对抓取、统计、获得的相关搜索热词、命中率、分类、搜索量、点击率、阅读量等相关数据，未经公司事先书面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三人；</u></strong></p>
    <p>（2）<strong><u>不得对商政通产品及相关服务的源网页进行任何形式的任何改动，包括但不限于商政通产品及相关服务的首页链接，广告系统链接等入口，也不得对商政通产品及相关服务的源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍；</u></strong></p>
    <p>（3）<strong><u>应当采取安全、有效、严密的措施，防止商政通产品及相关服务的信息内容被第三方通过包括但不限于“蜘蛛（spider）”程序等任何形式进行非法获取；</u></strong></p>
    <p>（4）<strong><u>不得把相关数据内容用于公司书面许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用；</u></strong></p>
    <p>（5）向任何第三人分享、转发、复制商政通产品及相关服务信息内容的行为，应当遵守公司为此制定的其他规范和标准。</p>

    <h3>第六章 服务/广告的变更、使用</h3>
    <p>6.1 您理解并同意，公司提供的商政通产品及相关服务是按照现有技术和条件所能达到的现状提供的。公司会尽最大努力向您提供服务，确保服务的连贯性和安全性。<strong><u>您理解，公司不能随时预见和防范技术以及其他风险，包括但不限于不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常使用本产品及服务以及其他的损失和风险。</u></strong></p>
    <p>6.2 <strong><u>您理解并同意，公司为了服务整体运营、平台运营安全的需要，有权视具体情况有权决定服务/功能设置、范围，修改、中断、中止或终止商政通产品及相关服务。</u></strong></p>
    <p>6.3 <strong><u>广告</u></strong></p>
    <p>6.3.1 <strong><u>您理解并同意，在使用商政通产品及相关服务过程中公司可能会向您推送具有相关性的信息、广告发布或品牌推广服务，且公司将在商政通产品及相关服务中展示商政通产品及相关服务相关和/或第三方供应商、合作伙伴的商业广告、推广或信息（包括商业或非商业信息）。</u></strong></p>
    <p>6.3.2 <strong><u>在使用商政通时，公司可能会基于偏好、兴趣等方面向您推送程序化广告，您可以在商政通设置中关闭程序化广告的推送，如果关闭，您看到的广告数量将保持不变，但广告的相关性会降低。您可以退订我们发送给您的营销短信。</u></strong></p>
    <p>6.3.3 公司依照法律规定履行广告及推广相关义务，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，您因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失由您自行承担，公司不予承担责任。</p>
    
    <h3>第七章 知识产权</h3>
    <p>7.1 公司在商政通产品及相关服务中提供的内容（包括但不限于产品、技术、程序、网页、文字、图片、图像、图表、版面设计、电子文档等）的知识产权属于公司所有。公司提供本服务时所依托的产品的著作权、专利权及其他知识产权均归公司所有。未经公司许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载）商政通产品及相关服务中的内容。</p>
    <p>7.2 <strong><u>您确认并同意授权公司以公司自己的名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，公司有权对维权事宜做出决策并独立实施。</u></strong></p>
    <p>7.3 公司为商政通开发、运营提供技术支持，并对商政通产品及相关服务的开发和运营等过程中产生的所有数据和信息等享有法律法规允许范围内的全部权利。</p>
    <p>7.4 <strong><u>请您在任何情况下都不要私自使用公司的包括但不限于商政通和“<span>https://zc.vipisall.com</span>”等在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。</u></strong>未经公司事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您违反本协议使用公司上述商标、标识等给公司或他人造成损失的，由您承担全部法律责任。</p>


    <h3>第八章 免责声明</h3>
    <p>8.1 您理解并同意，商政通产品及相关服务可能会受多种因素的影响或干扰，公司不保证(包括但不限于)：</p>
    <p>8.1.1 <strong><u>商政通产品及相关服务完全适合用户的所有使用要求；</u></strong></p>
    <p>8.1.2<strong><u> 商政通产品及相关服务不受干扰，及时、安全、可靠或不出现任何错误；您经由公司取得的任何产品、服务或其他材料符合用户的期望；</u></strong></p>
    <p>8.1.3 <strong><u>商政通产品及相关服务中任何错误都将能得到更正。</u></strong></p>
    <p>8.2 您理解并同意，在使用商政通产品及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于自然灾害(如洪水、地震、台风等)、政府行为、战争、罢工、骚动、暴乱等。出现不可抗力情况时，公司将努力在第一时间及时修复，但因不可抗力造成的损失，公司在法律法规范围内免于承担责任。</p>
    <p>8.3 <strong><u>公司依据本协议约定获得处理违法违规内容的权利，该权利不构成公司的义务或承诺，公司不能保证及时发现违法行为或进行相应处理。</u></strong></p>
    <p>8.4 <strong><u>您理解并同意，关于商政通产品及相关服务，公司不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对商政通产品及相关服务的使用行为需自行承担相应风险。</u></strong></p>
    <p>8.5 您理解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果您已经理解并同意自行承担。</p>

  </div>
</template>
<style lang="scss" scoped>
.protocol {
  padding: 20px 40px;
  line-height: 2;
}

h1 {
  margin: 10px 0;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

h2 {
  margin: 10px 0;
  font-size: 18px;
}

h3 {
  margin: 10px 0;
  font-size: 16px;
}

p {
  margin: 0.8em 0;
  font-size: 14px;
  text-align: justify;
  span{
    color: red;
  }
}

a {
  text-decoration: underline;
}

time {
  display: block;
  margin: 20px 0;
  font-size: 12px;
  text-align: center;
  > span {
    display: inline-block;
    margin: 0 20px;
  }
}
</style>
<script>


export default {

}
</script>