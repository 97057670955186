import { redirectSSOLogin, handleSSOLoginOut } from "@/utils/sso-login-utils";
import common from '@/utils/common.js'
import router from '../router'
import { checkLogin } from "@/utils/api";
export const islogin = () => {
    let userTemp = JSON.parse(common.getStorage("_user"));
    if (userTemp == null || userTemp == undefined) {
        redirectSSOLogin();
    }
}

// 判断是否登录
export function islogined() {
    let userTemp = JSON.parse(common.getStorage("_user"));
    if (userTemp == null || userTemp == undefined) {
        return false;
    }
    return true;
}

//判断是否用户登录

export async function isUserLogin() {  
    let result = false;  
    let userTemp;  
  
    try {  
        // 尝试从存储中获取用户信息  
        const userStorage = common.getStorage("_user");  
        userTemp = JSON.parse(userStorage);  
        console.log(userTemp, "userTemp1");  
  
        // 检查用户信息是否存在  
        if (!userTemp || !userTemp.chanChuangUserInfo||userTemp.chanChuangUserInfo.type === 1) {  
            console.log(2); // 或者使用更具体的日志来区分这两种情况  
            return result; // 直接返回 false  
        }  
  
        // 调用 checkLogin 函数并等待其完成  
        const res = await checkLogin();  
  
        // 根据 checkLogin 的结果设置 result  
        if (res.succeeded) {  
            console.log(res, "checkLogin");  
            // 由于已经解析了 userTemp，这里不需要再次从存储中获取  
            if (userTemp.chanChuangUserInfo.type === 1) {  
                console.log(3);  
                result = false;  
            } else {  
                console.log(4);  
                result = true;  
            }  
        } else {  
            console.log(5);  
            result = false;  
        }  
    } catch (error) {  
        // 处理在解析 JSON 或调用 checkLogin 时可能发生的错误  
        console.log(6, error);  
        result = false;  
    }  
  
    console.log(result, "result");  
    return result; // 确保返回 result  
}

// export function isUserLogin() {  
//     return new Promise((resolve, reject) => {  
//         let result = false;  
//         let userTemp;  
  
//         try {  
//             // 尝试从存储中获取用户信息  
//             const userStorage = common.getStorage("_user");  
//             userTemp = JSON.parse(userStorage);  
//             console.log(userTemp, "userTemp1");  
  
//             // 检查用户信息是否存在  
//             if (!userTemp || !userTemp.chanChuangUserInfo || userTemp.chanChuangUserInfo.type === 1) {  
//                 console.log(2);  
//                 resolve(false); // 直接返回 false  
//                 return;  
//             }  
  
//             // 调用 checkLogin 函数，不等待其完成，但处理其结果  
//             checkLogin().then(res => {  
//                 // 根据 checkLogin 的结果设置 result  
//                 if (res.succeeded) {  
//                     console.log(res, "checkLogin");  
//                     if (userTemp.chanChuangUserInfo.type === 1) {  
//                         console.log(3);  
//                         result = false;  
//                     } else {  
//                         console.log(4);  
//                         result = true;  
//                     }  
//                 } else {  
//                     console.log(5);  
//                     result = false;  
//                 }  
//                 resolve(result); // 使用 resolve 返回最终结果  
//             }).catch(error => {  
//                 // 处理在调用 checkLogin 时可能发生的错误  
//                 console.log(6, error);  
//                 reject(error); // 使用 reject 传递错误  
//             });  
//         } catch (error) {  
//             // 处理在解析 JSON 时可能发生的错误  
//             console.log(6, error);  
//             reject(error); // 使用 reject 传递错误  
//         }  
//     });  
// }
//401 清除登录信息
export function _401_clearstorage() {
    localStorage.removeItem("token");
    // 跳转登录
    localStorage.removeItem("_user")
    localStorage.removeItem("_tokenKey")
    localStorage.removeItem("_refreshTokenKey")
    localStorage.removeItem("_MKA")
    localStorage.removeItem("_UIF")
    console.log(router.currentRoute);
    router.push({
        path: '/User/Login',
        // query: { redirect: router.currentRoute.fullPath } //从哪个页面跳转
    })
    redirectSSOLogin();
}