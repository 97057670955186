import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/global.scss'
import App from './App.vue'
import router from './router'
import messagebox from '@/utils/messageInfo.js'
import common from '@/utils/common.js'
import tool from "./utils/tool"
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont/iconfont.js';
import filter from './utils/timeFilter'
import { browserRedirect } from './utils/index.js'
import VueCropper from 'vue-cropper'
import intro from "intro.js"; // introjs库
import "intro.js/introjs.css"; // introjs默认css样式 
Vue.prototype.$intro = intro; //如有需要 main.js
import * as echarts from "echarts" //引入echarts
//  import  _vconsole_ from 'vconsole';
import VueWechatTitle from 'vue-wechat-title';
Vue.use(VueWechatTitle);
Vue.prototype.$echarts = echarts //注册组件
import axios from 'axios'  // 安装axios后引入
Vue.prototype.$axios = axios  // 将axios挂载到原型上方便使用
import {
  wechatlogincallback, qqlogincallback
} from "@/utils/api";
import BehaviorLog from '@/utils/log';

for (const key in filter) {
  Vue.filter(key, filter[key]);
}
import global from './common.vue'
import { parseTime, getDiffDay } from "./utils/common";
import { downFile } from "./utils/http";
Vue.prototype.global = global
Vue.use(Element, VueAwesomeSwiper);
Vue.use(tool);
Vue.use(VueCropper);
// let vConsole = new _vconsole_()
// Vue.use(vConsole);
Vue.prototype.$MessageBox = messagebox
Vue.prototype.$Common = common
Vue.config.productionTip = false
Vue.prototype.parseTime = parseTime;
Vue.prototype.getDiffDay = getDiffDay;
Vue.prototype.downFile = downFile;
//console.log(process.env.VUE_APP_BASE_API);
router.beforeEach((to, from, next) => {
  console.log(to);
    console.log(from);
  var logData = {
    // to: to,
    // from: from.name,
    // browser: browserRedirect(),
    // userAgent: navigator.userAgent
  }
  let userTemp = JSON.parse(common.getStorage("_user"));
  if (userTemp != null && userTemp != undefined) {
    logData.userId = userTemp.id;
  }
  else {
    logData.userId = 0;
  }
  //console.log(logData);

  //访问日志开始
  var Logmodel = {
    kind: 1,
    routerName: to.meta.title,
    accessAddress: to.fullPath,
    userId: logData.userId,
    sourceAddress: from.name,
    operatingSystem: browserRedirect(),
    browserVersion: to.userAgent,
    pageId: to.name,
  }
  //路径去掉？后的参数
  if(Logmodel.accessAddress.indexOf('?')>-1)
  {
   Logmodel.accessAddress=Logmodel.accessAddress.split('?')[0];
  }
  // //是否是详情页面 有参数 (记录页面已修改)
  // if (to.fullPath.indexOf('/Search/Detail/') > -1) {
  //   Logmodel.detailId = to.params.id;
  //   Logmodel.accessAddress = '/Search/Detail';
  // }
   if (to.fullPath.indexOf('/policy/') > -1) {
    Logmodel.detailId = to.params.id;
    Logmodel.accessAddress = '/policy';
  }
  //视频详情页面 有参数
  if (to.fullPath.indexOf('/Interpretation/Detail/') > -1) {
    Logmodel.detailId = to.params.id;
    Logmodel.accessAddress = '/Interpretation/Detail';
  }
  
  var logmodel = new BehaviorLog(Logmodel);
  logmodel.AccessLog();
//访问日志记录结束



  var tourl = to.fullPath;
  var query = to.query;
  //console.log(query);
  if (_hmt) {
    if (to.path) {
      _hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  if (tourl.indexOf('code') > -1 && tourl.indexOf('state') > -1) {
    //console.log(query.code);
    // console.log(query.state);
    if (query.state.indexOf('wechat_') > -1) {
      var data = {
        code: query.code,
        state: query.state,
        currentPath: ''
      }
      wechatlogincallback(data).then((res) => {
        //console.log(res);
        if (res.statusCode == 200 && res.data.apiStatusCode == 1000) {
          var response = res.data.data;
          if (response.type == 'login') {
            //写登录数据
            // console.log(common);
            // console.log(JSON.stringify(response.data));
            common.setStorage("_user", JSON.stringify(response.data), 60 * 1000 * 1440 * 30);
            common.setStorage("_login", "0", 60 * 1000 * 1440 * 30, false);
            // var url = common.getStorage("wechatpath");
            // console.log(url);
            //  //this.$router.push({query:{}});
            //
            if (localStorage.getItem("_HID")) {
              let hid = JSON.parse(localStorage.getItem("_HID")).reverse();
              for (var i = 0; i < hid.length; i++) {
                if (
                  hid[i].indexOf("/User/Login") == -1 &&
                  hid[i].indexOf("/User/BindPhone") == -1 &&
                  hid[i] != "/User/Register" &&
                  hid[i] != "/User/passwordRetrieval"
                ) {
                  //this.$router.replace(hid[i]);              
                  next({ path: hid[i] });
                  break;
                }
                if (i == hid.length - 1) {
                  //this.$router.replace(encodeURI("/"));
                  next({ path: encodeURI("/") });
                }
              }
            }
          }
          if (response.type == 'bind') {
            //跳转绑定页面
            var logintype = response.logintype;
            common.setStorage("wechatunionid", response.uid);
            next({ path: "/User/BindPhone", query: { ltype: logintype } });
          }

        }
      }).catch(() => {
      });

    }
    if (query.state.indexOf('qq_') > -1) {
      //console.log(query.state);
      var qqdata = {
        code: query.code,
        state: query.state,
        currentPath: ''
      }
      qqlogincallback(qqdata).then((res) => {
        //console.log(res);
        if (res.statusCode == 200 && res.data.apiStatusCode == 1000) {
          var response = res.data.data;
          if (response.type == 'login') {
            //写登录数据
            // console.log(common);
            //  console.log(JSON.stringify(response.data));
            common.setStorage("_user", JSON.stringify(response.data), 60 * 1000 * 1440 * 30);
            common.setStorage("_login", "0", 60 * 1000 * 1440 * 30, false);
            // var url = common.getStorage("wechatpath");
            // // console.log(url);
            // //this.$router.push({ query: {} });
            // next({ path: url.wechatredrictpath });
            if (localStorage.getItem("_HID")) {
              let hid = JSON.parse(localStorage.getItem("_HID")).reverse();
              for (var i = 0; i < hid.length; i++) {
                if (
                  hid[i].indexOf("/User/Login") == -1 &&
                  hid[i].indexOf("/User/BindPhone") == -1 &&
                  hid[i] != "/User/Register" &&
                  hid[i] != "/User/passwordRetrieval"
                ) {
                  //this.$router.replace(hid[i]);              
                  next({ path: hid[i] });
                  break;
                }
                if (i == hid.length - 1) {
                  //this.$router.replace(encodeURI("/"));
                  next({ path: encodeURI("/") });
                }
              }
            }
          }
          if (response.type == 'bind') {
            //跳转绑定页面
            var logintype = response.logintype;
            common.setStorage("qqunionid", response.uid);
            next({ path: "/User/BindPhone", query: { ltype: logintype } });
          }
        }
      }).catch(() => {
      });
    }
    next();
    // this.router.push({query:{}});
  }
  else {
    next();
  }
});

var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?6ba144e85495f289ed84bd4b62e34a1e";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

//按钮点击日志记录开始
Vue.directive('log', {
  bind(el, binding) {
  //  console.log(el);
    //只实现了附件下载日志
    el.addEventListener('click', () => {

      //console.log(binding.value);
      var bindmodel = binding.value;

      var clickLogmodel = {
        kind: bindmodel.kind,
        attachmentName: bindmodel.attachmentName,
        detailId: bindmodel.detailId,
        // routerName: to.meta.title,
        // accessAddress: to.fullPath,
        // userId: logData.userId,
        // sourceAddress: from.name,
        operatingSystem: browserRedirect(),
        // browserVersion: to.userAgent,
        // pageId: to.name,
        buttonID:bindmodel.buttonID
      }
       //搜索按钮
      if (bindmodel.kind==3&& bindmodel.buttonID == 10) {
       
       // console.log('检索')
        let search = common.getStorage("Search", false);
        if (search != undefined) {
          var queryModel = search;
          var fieldIdentifier = queryModel.fieldIdentifier;
          var searchKey = queryModel.searchKey;
          clickLogmodel.searchKey=searchKey;
          clickLogmodel.retrievalType=fieldIdentifier + "=" + searchKey;
          clickLogmodel.buttonID=bindmodel.buttonID;
        }
      }
      //检索结果列表
      if(bindmodel.kind==4)
      {
       
        var i=parseInt(((el.dataset.p-1)* el.dataset.s))+parseInt(el.dataset.i);
        //console.log(i);
        bindmodel.buttonID=parseInt(i)+1;
        //console.log( bindmodel.buttonID);
        let search = common.getStorage("Search", false);
        if (search != undefined) {
          var _queryModel = search;
          var _fieldIdentifier = _queryModel.fieldIdentifier;
          var _searchKey = _queryModel.searchKey;
          clickLogmodel.searchKey=_searchKey;
          clickLogmodel.retrievalType=_fieldIdentifier + "=" + _searchKey;
          clickLogmodel.buttonID=bindmodel.buttonID;
        }
      }
      //聚类按钮
      if (bindmodel.kind==3&& bindmodel.buttonID == 9)
      {
      var _clusterAnalysis=bindmodel.title;
        var dic={
          "date":1,
          "theme":2,
          "level":3,
          "centrelevel":4,
          "districtlevel":5,
          "style":6,
          "organ":7,
          "applicable_trade":8,
        };
        if(bindmodel.title.indexOf('centrelevel')>-1)
        {
          _clusterAnalysis="centrelevel";
        }
        if(bindmodel.title.indexOf('districtlevel')>-1)
        {
          _clusterAnalysis="districtlevel";
        }
        clickLogmodel.clusterAnalysis=dic[_clusterAnalysis];
        clickLogmodel.buttonID=bindmodel.buttonID;
      }
     // console.log(clickLogmodel);
      var _clicklogmodel = new BehaviorLog(clickLogmodel);
      _clicklogmodel.AccessLog();

    }, false);
  }
});
//按钮点击日志记录结束
new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
