import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home/Index.vue"),
    meta: {
      isIndex: true,
      title: '商政通-首页'

    }
  },
  {
    path: encodeURI("/Search/Index"),
    name: "Search",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Search/Index.vue"),
    meta: {
      isIndex: false,
      title: '商政通-检索页'
    }
  },
  {
    path: encodeURI("/Search/Detail/:id"),
    name: "Detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Search/Detail.vue"),
    meta: {
      isIndex: false,
      title: '商政通-详情页'
    }
  },
  {
    path: encodeURI("/Policy/:id"),
    name: "Policy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Policy/index.vue"),
    meta: {   
      title: '商政通-政策详情'
    }
  },
  //测试
  {
    path: encodeURI("/line/:id"),
    name: "Line",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Policy/line.vue"),
    meta: {   
      title: '商政通-政策详情'
    }
  },
  {
    path: encodeURI("/Chat"),
    name: "Chat",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Chat/index.vue"),
    meta: {   
      title: '商政通-AI问政'
    }
  },
  {
    path: encodeURI("/Chat1"),
    name: "Chat1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Chat/index1.vue"),
    meta: {   
      title: '商政通-AI问政'
    }
  },
  {
    path: encodeURI("/Subscribe"),
    name: "Subscribe",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Subscribe/index.vue"),
    meta: {   
      title: '商政通-政策订阅'
    }
  },
  {
    path: encodeURI("/Interpretation"),
    name: "Interpretation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Interpretation/index.vue"),
    meta: {   
      title: '商政通-政策解读'
    }
  },
  {
    path: encodeURI("/Interpretation/Detail/:id"),
    name: "Interpretation-Detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Interpretation/Detail.vue"),
    meta: {   
      title: '商政通-政策解读详细'
    }
  },
  {
    path: encodeURI("/Archives/Index"),
    name: "ArchivesIndex",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Archives/Index.vue"),
    meta: {
      isArchives: true,
      sub: 0,
      title: '商政通-我的档案首页'
    }
  },
  {
    path: encodeURI("/Note/myNote/"),
    name: "myNote",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Note/myNote.vue"),
    meta: {
      isArchives: true,
      sub: 1,
      title: '商政通-我的笔记'
    }
  },
  {
    path: encodeURI("/Note/noteBook"),
    name: "noteBook",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Note/noteBook.vue"),
    meta: {
      isArchives: true,
      sub: 1,
      title: '商政通-笔记本'
    }
  },
  {
    path: encodeURI("/Note/labelManage"),
    name: "labelManage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Note/labelManage.vue"),
    meta: {
      isArchives: true,
      sub: 1,
      title: '商政通-标签管理'
    }
  },
  {
    path: encodeURI("/Note/policyStarMap"),
    name: "policyStarMap",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Note/policyStarMap.vue"),
    meta: {
      isArchives: true,
      sub: 1,
      title: '商政通-政策星图'
    }
  },
  {
    path: encodeURI("/User/orderRead"),
    name: "orderRead",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/User/orderRead.vue"),
    meta: {
      isArchives: true,
      sub: 1,
      title: '商政通-我的订阅'
    }
  },
  {
    path: encodeURI("/User/myFollow"),
    name: "myFollow",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/User/myFollow.vue"),
    meta: {
      isArchives: true,
      sub: 1,
      title: '商政通-我的关注'
    }
  },
  {
    path: encodeURI("/User/myCollect"),
    name: "myCollect",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/User/myCollect.vue"),
    meta: {
      isArchives: true,
      sub: 1,
      title: '商政通-我的收藏'
    }
  },
  {
    path: encodeURI("/User/myReport"),
    name: "myReport",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/User/myReport.vue"),
    meta: {
      isArchives: true,
      sub: 1,
      title: '商政通-相似报告'
    }
  },
  {
    path: encodeURI("/Search/PolicyNav"),
    name: "PolicyNav",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Search/PolicyNav.vue"),
    meta: {
      isIndex: false,
      title: '商政通-政策导航'
    }
  },
  {
    path: encodeURI("/Navigation/Institution"),
    name: "Navigation-Institution",  
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Navigation/Institution.vue"),
    meta: { 
      title: '商政通-发文机关导航'
    }
  },
  {
    path: encodeURI("/Navigation/Industry"),
    name: "Navigation-Industry",  
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Navigation/Industry.vue"),
    meta: { 
      title: '商政通-产业分类导航'
    }
  },
  {
    path: encodeURI("/IndustryChain/:id"),
    name: "IndustryChain",  
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/IndustryChain/index.vue"),
    meta: { 
      title: '商政通-产业链详情'
    }
  },
  {
    path: encodeURI("/KnowledgeBase"),
    name: "KnowledgeBase",  
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KnowledgeBase/index.vue"),
    meta: { 
      title: '商政通-政策知识库'
    }
  },


  {
    path: encodeURI("/html/个人中心"),
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/个人中心.vue"),
    meta: {
      isUsercenter: true
    }
  },
  {
    path: encodeURI("/html/我的收藏"),
    name: "mysearch1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/我的收藏.vue"),
    meta: {
      isUsercenter: true
    }
  },

  {
    path: encodeURI("/html/首页"),
    name: "message1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/首页.vue"),
    meta: {
      isUsercenter: true
    }
  },

  {
    path: encodeURI("/html/会员服务"),
    name: "seehistry1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/会员服务.vue"),
    meta: {
      isUsercenter: true
    }
  },
  {
    path: encodeURI("/html/微信支付"),
    name: "seehistry1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/微信支付.vue"),
    meta: {
      isUsercenter: true
    }
  },
  {
    path: encodeURI("/html/个人信息"),
    name: "seehistry1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/个人信息.vue"),
    meta: {
      isUsercenter: true
    }
  },
  {
    path: encodeURI("/html/订阅管理"),
    name: "userinfo1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/订阅管理.vue"),
    meta: {
      isSingle: true,
    }
  },
  {
    path: encodeURI("/html/检索列表页"),
    name: "setdy1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/检索列表页.vue"),
    meta: {
      isUsercenter: true
    }
  },
  {
    path: encodeURI("/html/修改密码"),
    name: "userUpdataPwd1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/修改密码.vue"),
    meta: {
      isUsercenter: true
    }
  },
  {
    path: encodeURI("/User/Login"),
    name: "UserLogin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      //import(/* webpackChunkName: "about" */ "../views/User/Login.vue"),
      import(/* webpackChunkName: "about" */ "../views/sso_login.vue"),
    meta: {
      isIndex: false,
      title: '商政通-登录页'
    }

  },
  // {
  //   path: encodeURI("/User/Register"),
  //   name: "Register",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/User/Register.vue"),
  //   meta: {
  //     isIndex: false,
  //     title: '商政通-注册页'
  //   }
  // },
  {
    path: encodeURI("/html/注册"),
    name: "register1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/注册.vue"),
    meta: {

    }
  },
  {
    path: encodeURI("/html/忘记密码"),
    name: "getpassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/忘记密码.vue"),
    meta: {

    }
  },
  {
    path: encodeURI("/html/忘记密码"),
    name: "getpassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/忘记密码.vue"),
    meta: {

    }
  },
  {
    path: encodeURI("/html/登录"),
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/登录.vue"),
    meta: {

    }
  },
  {
    path: encodeURI("/html/账号绑定"),
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/账号绑定.vue"),
    meta: {

    }
  },
  // {
  //   path: encodeURI("/User/Index"),
  //   name: "UserIndex",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/User/Index.vue"),
  //   meta: {
  //     isIndex: false,
  //     isUsercenter: true,
  //     title: '商政通-个人中心'
  //   }
  // },
  {
    path: encodeURI("/Member/MemService"),
    name: "UserIndex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Member/MemService.vue"),
    meta: {
      isIndex: false,
      isUsercenter: true,
      title: '商政通-会员服务'
    }
  },
  {
    path: encodeURI("/html/政策文献"),
    name: "setwx1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/政策文献.vue"),
    meta: {

    }
  },
  {
    path: encodeURI("/html/档案馆首页"),
    name: "setwx1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/档案馆首页.vue"),
    meta: {
      isSingle: true,
    }
  },
  {
    path: encodeURI("/html/我的笔记"),
    name: "setwx1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/我的笔记.vue"),
    meta: {
      isSingle: true,
    }
  },
  {
    path: encodeURI("/html/笔记本"),
    name: "setwx1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/笔记本.vue"),
    meta: {
      isArchives: true,
    }
  },
  {
    path: encodeURI("/html/标签管理"),
    name: "setwx1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/标签管理.vue"),
    meta: {
      isSingle: true,
    }
  },
  {
    path: encodeURI("/html/政策星图"),
    name: "setwx1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../html/政策星图.vue"),
    meta: {
      isSingle: true,
    }
  },
  {
    path: encodeURI("/Special/Index"),
    name: "SpecialIndex",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Special/Index"),
    meta: {
      isIndex: false,
      title: '商政通-专题列表'
    }
  },
  {
    path: encodeURI("/Special/List/:id"),
    name: "SpecialList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Special/List"),
    meta: {
      isIndex: false,
      isUsercenter: true,
      title: '商政通-专题详情'
    }
  },
  {
    path: "/Clause",
    name: "ClauseSearch",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/Clause/Index"),
    meta: {
      isIndex: false,
      isUsercenter: true,
      title: '商政通-条文查询'
    }
  },
  // {
  //   path: encodeURI("/User/passwordRetrieval"),
  //   name: "passwordRetrieval",
  //   component: () =>
  //     import("../views/User/passwordRetrieval.vue"),
  //   meta: {
  //     isIndex: false,
  //     isUsercenter: true,
  //     title: '商政通-忘记密码'
  //   }
  // },
  // {
  //   path: encodeURI("/User/BindPhone"),
  //   name: "UserBindPhone",
  //   component: () =>
  //     import("../views/User/BindPhone.vue"),
  //   meta: {
  //     isIndex: false,
  //     title: '商政通-绑定手机号'
  //   }
  // },
  {
    path: encodeURI("/html/editor"),
    name: "Editor",
    component: () =>
      import("../html/editor.vue"),
    meta: {
      isIndex: false,
      title: '商政通-绑定编辑'
    }
  },
  {
    path: encodeURI("/MSubscribe/Mindex"),
    name: "Mindex",
    component: () =>
      import("../views/MSubscribe/Mindex.vue"),
    meta: {
      isMobile: true,
      title: '商政通-H5消息推送结果'
    }
  },
  {
    path: encodeURI("/MSubscribe/Msubscribepolicy"),
    name: "Msubscribepolicy",
    component: () =>
      import("../views/MSubscribe/Msubscribepolicy.vue"),
    meta: {
      isMobile: true,
      title: '商政通-H5订阅政策'
    }
  },
  {
    path: encodeURI("/MSubscribe/MDetail"),
    name: "MDetail",
    component: () =>
      import("../views/MSubscribe/MDetail.vue"),
    meta: {
      isMobile: true,
      title: '商政通-H5政策详情'
    }
  },
  {
    path: encodeURI("/MSubscribe/Redrict"),
    name: "Redrict",
    component: () =>
      import("../views/MSubscribe/Redrict.vue"),
    meta: {
      isMobile: true,
      title: '商政通-H5登录回调页面'
    }
  },
  {
    path: encodeURI("/MSubscribe/MPhoneLogin"),
    name: "MPhoneLogin",
    component: () =>
      import("../views/MSubscribe/MPhoneLogin.vue"),
    meta: {
      isMobile: true,
      title: '商政通-H5手机登录'
    }
  },
  {
    path: encodeURI("/html/M0"),
    name: "Editor",
    component: () =>
      import("../html/M登录.vue"),
    meta: {
      isMobile: true,
      title: '商政通-登录'
    }
  },
  {
    path: encodeURI("/html/M1"),
    name: "Editor",
    component: () =>
      import("../html/M订阅推送.vue"),
    meta: {
      isMobile: true,
      title: '商政通-订阅推送'
    }
  },
  {
    path: encodeURI("/html/M2"),
    name: "Editor",
    component: () =>
      import("../html/M政策法规.vue"),
    meta: {
      isMobile: true,
      title: '商政通-政策法规'
    }
  },
  {
    path: encodeURI("/html/M3"),
    name: "Editor",
    component: () =>
      import("../html/M订阅"),
    meta: {
      isMobile: true,
      title: '商政通-政策订阅'
    }
  },
  {
    path: encodeURI("/html/healthy"),
    name: "Editor",
    component: () =>
      import("../html/健康检测"),
    meta: {
      isMobile: true,
      title: '商政通-健康检测'
    }
  },
  {
    path: encodeURI("/html/视频列表"),
    name: "Editor",
    component: () =>
      import("../html/视频列表"),
    meta: {
      isMobile: false,
      title: '商政通-视频列表'
    }
  },
  {
    path: encodeURI("/html/视频详细"),
    name: "Editor",
    component: () =>
      import("../html/视频详细"),
    meta: {
      isMobile: false,
      title: '商政通-视频详细'
    }
  },
  {
    path: encodeURI("/VideoDetail/VideoPlay"),
    name: "VideoPlay",
    component: () =>
      import("../views/VideoDetail/VideoPlay.vue"),
    meta: {
      isIndex: false,
      title: '商政通-视频播放'
    }
  },
  {
    path: encodeURI("/VideoDetail/VideoPlay1"),
    name: "VideoPlay",
    component: () =>
      import("../views/VideoDetail/VideoPlay1.vue"),
    meta: {
      isIndex: false,
      title: '商政通-视频播放'
    }
  },
  {
    path: encodeURI("/html/政策报告"),
    name: "Editor",
    component: () =>
      import("../html/政策报告"),
    meta: {
      isMobile: false,
      title: '商政通-政策报告'
    }
  },
  {
    path: encodeURI("/Search/Analysis"),
    name: "Search-Analysis",
    component: () =>
      import("../views/Search/Analysis.vue"),
    meta: {
      isIndex: false,
      title: '商政通-政策报告'
    }
  },

  {
    path: encodeURI("/Analysis/Level"),
    name: "Level",
    component: () =>
      import("../views/Analysis/Level.vue"),
    meta: {
      isMobile: false,
      title: '商政通-政策层级分布图'
    }
  },

  {
    path: encodeURI("/Analysis/YearOfIssue"),
    name: "YearOfIssue",
    component: () =>
      import("../views/Analysis/YearOfIssue.vue"),
    meta: {
      isMobile: false,
      title: '商政通-发文年份分布图'
    }
  },
  {
    path: encodeURI("/Analysis/Cooccurrence"),
    name: "Cooccurrence",
    component: () =>
      import("../views/Analysis/Cooccurrence.vue"),
    meta: {
      isMobile: false,
      title: '商政通-发文主题共现图'
    }
  },
  {
    path: encodeURI("/Analysis/Topic"),
    name: "Topic",
    component: () =>
      import("../views/Analysis/Topic.vue"),
    meta: {
      isMobile: false,
      title: '商政通-发文热点分布图'
    }
  },
  {
    path: encodeURI("/Analysis/IssuingAuthority"),
    name: "IssuingAuthority",
    component: () =>
      import("../views/Analysis/IssuingAuthority.vue"),
    meta: {
      isMobile: false,
      title: '商政通-发文机关分布图'
    }
  },
  {
    path: encodeURI("/Analysis/CentralPolicy"),
    name: "CentralPolicy",
    component: () =>
      import("../views/Analysis/CentralPolicy.vue"),
    meta: {
      isMobile: false,
      title: '商政通-中央政策发布机关分布图'
    }
  },
  {
    path: encodeURI("/Analysis/ProvincialMap"),
    name: "ProvincialMap",
    component: () =>
      import("../views/Analysis/ProvincialMap.vue"),
    meta: {
      isMobile: false,
      title: '商政通-地方政策省域分布图'
    }
  },
  {
    path: encodeURI("/html/全文对照"),
    name: "Editor",
    component: () =>
      import("../html/全文对照.vue"),
    meta: {
      isCompare: true,
      title: '全文对照'
    }
  },
  {
    path: encodeURI("/html/片段对比"),
    name: "Editor",
    component: () =>
      import("../html/片段对比.vue"),
    meta: {
      isCompare: true,
      title: '片段对比'
    }
  },
  {
    path: encodeURI("/html/政策相似性提交"),
    name: "Editor",
    component: () =>
      import("../html/政策相似性提交.vue"),
    meta: {

      title: '政策相似性提交'
    }
  },
  {
    path: encodeURI("/VideoDetail/InterpretationList"),
    name: "InterpretationList",
    component: () =>
      import("../views/VideoDetail/InterpretationList.vue"),
    meta: {
      isMobile: false,
      title: '商政通-政策解读'
    }
  },
  {
    path: encodeURI("/VideoDetail/InterpretationDetail/:id"),
    name: "InterpretationDetail",
    component: () =>
      import("../views/VideoDetail/InterpretationDetail.vue"),
    meta: {
      isMobile: false,
      title: '商政通-政策解读详情'
    }
  },
  {
    path: encodeURI("/User/FulltextCross/:id"),
    name: "FulltextCross",
    component: () =>
      import("../views/User/FulltextCross.vue"),
    meta: {

      title: '商政通-全文对照'
    }
  },
  {
    path: encodeURI("/User/FragmentComparison/:id"),
    name: "FragmentComparison",
    component: () =>
      import("../views/User/FragmentComparison.vue"),
    meta: {
      title: '商政通-片段对比'
    }
  },
  {
    path: encodeURI("/html/print"),
    name: "print",
    component: () =>
    import("../html/print.vue"),
    meta: {
      isCompare: true,
      title: '商政通-片段对比'
    }
  }
];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  document.title = to.meta.title;
  // 存储历史记录
  if (localStorage.getItem("_HID")) {
    let historyData = JSON.parse(localStorage.getItem("_HID"))
    if (historyData[historyData.length - 1] != to.fullPath) {
      if (historyData.length < 5) {
        historyData.push(to.fullPath)
      } else {
        historyData.shift()
        historyData.push(to.fullPath)
      }
      localStorage.setItem("_HID", JSON.stringify(historyData))
    }
  } else {
    let historyData = []
    historyData.push(to.fullPath)
    localStorage.setItem("_HID", JSON.stringify(historyData))
  }
  //存储当前路由，用于菜单检测
  // localStorage.setItem("_MPTH",to.fullPath)
});

export default router;
