<template>
  <div class="tools" v-if="!isWechat">
    <div class="tool-item" @click="handleShow" >
      <div class="_icon">
        <i class="icon iconfont icon-dingyuetixing1"></i>
        <sup v-if="ordernewCount>0">{{ ordernewCount }}</sup>
      </div>
      <div class="_name">订阅提醒</div>
    </div>
    <div class="tool-item" @click="handldAI">
      <div class="_icon">
        <i class="icon iconfont icon-AIBot"></i>
      </div>
      <div class="_name">AI问政</div>
    </div>
    <div class="tool-item" @click="showfeed">
      <div class="_icon">
        <i class="icon iconfont icon-yijianwenzheng"></i>
      </div>
      <div class="_name">问题反馈</div>
    </div>
    <div class="tool-item chat">
      <div class="_icon">
        <i class="icon iconfont icon-zaixiankefu"></i>
      </div>
      <div class="_name">在线客服</div>
    </div>
    <div class="tool-item" @click="backTop">
      <div class="_icon">
        <i class="icon iconfont icon-huidaodingbu"></i>
      </div>
      <div class="_name">回到顶部</div>
    </div>
    <subscribeDialog ref="subscribeDialog" :visible="alertVisible" :timestamp="timestamp"></subscribeDialog>
    <el-dialog :append-to-body="true" :visible.sync="feedBackVisible" width="870px">
      <template slot="title">
        <span class="el-dialog__title">问题反馈</span>
        <span class="title-tip">感谢您在百忙之中给我们提交问题反馈报告！</span>
      </template>
      <div class="feedback">
        <div>
          <div class="label">反馈方向:</div>
          <div>
            <el-radio-group v-model="feedbackType">
              <el-radio label='1'>使用操作</el-radio>
              <el-radio label='2'>数据相关</el-radio>
              <el-radio label='3'>意见或建议</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div>
          <div class="label">反馈内容:</div>
          <div>
            <el-input type="textarea" :rows="10" v-model="feedbackContent" placeholder="请输入检索内容"></el-input>
          </div>
        </div>
        <div class="submit">
          <el-button type="primary" @click="addfeedback">提交反馈</el-button>
          <el-button @click="feedBackVisible = false">关闭页面</el-button>
        </div>
      </div>
    </el-dialog>
    <bindAltOrg ref="bindAltOrg"></bindAltOrg>
  </div>
</template>
<script>
import { EventBus } from '@/utils/event-bus.js';
import subscribeDialog from "@/views/Home/components/subscribeDialog";
import { getorderreadnewlist } from "@/utils/orderreadnew";
import { islogined, islogin,isUserLogin } from "@/utils/logincheck";
import bindAltOrg from "@/components/bindaltOrg";
import { feedback } from "@/utils/api"
export default {
  components: {
    subscribeDialog,
    bindAltOrg
  },
  props: ["ts"],
  data() {
    return {
      timestamp: undefined,
      alertVisible: false,
      ordernewCount: 0, //订阅提醒数量
      feedBackVisible: false,
      feedbackType: "1",
      feedbackContent: "",
      isWechat: false,//是否微信端
    };
  },
  created() {
    
  },
  watch: {
    ts() {
      if (islogined()) {
        this.getOrderreadnewList();
      }
    }
  },
  methods: {
    RefreshOrdernewCount() {
      this.getOrderreadnewList();
    },
    //订阅提醒
    getOrderreadnewList() {
      this.ordernewCount = 0
      getorderreadnewlist({ PageIndex: 1, PageSize: 999 }).then((res) => {
        if(res.data.data)
        {
          let list = res.data.data.pageData.items;
          list.forEach((element) => {
            this.ordernewCount += element.dayRefreshCount;
          });
        }
      });
    },
    async handleShow() {
     if (await isUserLogin()) {
        this.timestamp = new Date().getTime();
        this.alertVisible = true;
      } else {
        this.btnLoading = false;
        this.$refs.bindAltOrg.init("");
        return;
      }
    },
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async handldAI() {
      if(!await isUserLogin()){
        this.$refs.bindAltOrg.init("");
        return;
      }
      this.$router.push("/Chat");
    },
    showfeed()
    {
      this.feedBackVisible=true;
      this.feedBackContent='';
    },
    addfeedback() {
      var feed = {
        feedBackType: parseInt(this.feedbackType),
        feedBackContent: this.feedbackContent
      };
      feedback(feed).then(res => {
        if (res.data.apiStatusCode == 1000) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.feedBackVisible=false;
        }
      })
    }
  },
  mounted() {
    EventBus.$on('RefreshOrdernewCount', this.RefreshOrdernewCount);
    if(window.location.href.indexOf('/MSubscribe/')>=0)
    {
      this.isWechat = true;
      return;
    }
    let script = document.createElement("script");
    script.id = "qd288577689181d57d4118a8b79c25f35253d2c7bd57";
    script.src =
      "https://wp.qiye.qq.com/qidian/2885776891/81d57d4118a8b79c25f35253d2c7bd57";
    document.body.querySelector(".chat").appendChild(script);
  },
  beforeDestroy() {
    EventBus.$off('RefreshOrdernewCount', this.RefreshOrdernewCount);
  },
};
</script>
<style lang="scss" scoped>
.feedback {
  >div {
    display: flex;
    min-height: 24px;
    padding: 8px 0;
    line-height: 24px;
  }

  .label {
    margin-right: 8px;

    +div {
      flex: 1;
    }
  }

  .el-textarea {
    width: 100%;
  }

  .submit {
    justify-content: center;
  }
}
</style>
