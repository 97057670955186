// 刚刚封装的axios
import request from './http';

// 1.登陆获取token
// export const UserLogin = (query) => {
//     return request({
//         url: '/test/Auth',
//         method: 'post',
//         params: query
//     })
// }
export const UserLogin = (data) => {
    return request({
        url: '/userinfo/userlogin',
        method: 'post',
        data: data
    })
}

// 2 测试检索
export const TestSearch = () => {
    return request({
        url: '/test/Remote',
        method: 'post',
    })
}

// 3 检索
export const getSearchList = (data) => {
    return request({
        url: '/Search/search-list',
        method: 'post',
        data: data
    })
}

// 3 检索
export const getSearchListIndex = (data) => {
    return request({
        url: '/Search/SearcListIndex',
        method: 'post',
        data: data
    })
}

// 4 详情
export const getDetail = (query) => {
    return request({
        url: '/Search/detail',
        method: 'post',
        params: query
    })
}

// 4 详情2
export const getDetailUrl = (query) => {
    return request({
        url: '/Search/GetDetailUrl',
        method: 'post',
        params: query
    })
}

// 5 详情相关
export const getDetailRelation = (query) => {
    return request({
        url: '/Search/zlf-detail',
        method: 'post',
        params: query
    })
}
//6 获取验证码
export const getValidatorCode = () => {
    return request({
        url: '/userinfo/getverificationcode',
        method: 'get'
    })
}
//7 发送手机验证码（需要图形验证码）
export const sendPhoneCode = (query) => {
    return request({
        url: '/userinfo/sendphonecode',
        method: 'post',
        params: query
    })
}
//8 注册账号
export const submitRegister = (data) => {
    return request({
        url: '/userinfo/phoneregister',
        method: 'post',
        data: data
    })
}
//9.修改个人资料
export const submitUserInfo = (data) => {
    return request({
        url: '/userinfo/updateuserinfo',
        method: 'post',
        data: data
    })
}
//10.修改密码
export const submitUserPWD = (data) => {
    return request({
        url: '/userinfo/updatepassword',
        method: 'post',
        params: data
    })
}
//11.获取个人信息
export const GetUserInfo = () => {
    return request({
        url: '/usercenter/getusercenterinfo',
        method: 'post',
    })
}
//12.上传头像
export const upLoadAvatar = (data) => {
    return request({
        url: '/userinfo/uploadheadimage',
        method: 'post',
        data:data
    })
}
//13验证用户是否存在
export const IsExistUser = (data) => {
    return request({
        url: '/userinfo/IsExistUser',
        method: 'post',
        params:data
    })
}
//13发送手机验证码（直接发送验证码）
export const sendphonegetpwd = (data) => {
    return request({
        url: '/userinfo/sendphonegetpwd',
        method: 'post',
        params:data
    })
}
//13发送手机验证码（直接发送验证码）
export const veryCode = (data) => {
    return request({
        url: '/userinfo/veryCode',
        method: 'post',
        data:data
    })
}
//14.修改密码
export const forget_Password = (data) => {
    return request({
        url: '/userinfo/getpassword',
        method: 'post',
        data:data
    })
}

//政策专题 start
//1.获取所有专题列表
export const GetSpecialList = (data) => {
    return request({
        url: '/spercia/getsperciaquerypage',
        method: 'post',
        data: data
    })
}

//政策专题 start
//1.获取所有专题列表
export const GetSpecialListIndex = (data) => {
    return request({
        url: '/spercia/getsperciaquerypageIndex',
        method: 'post',
        data: data
    })
}

//2.获取单独专题列表
export const GetAloneSpecialList = (data) => {
    return request({
        url: '/spercia/getsperciayeargroupquerypage',
        method: 'post',
        data: data
    })
}
//2.1 按照id获取单独专题列表
export const GetAloneSpecialSubList = (data) => {
    return request({
        url: '/spercia/getsperciayearpublishdetailquerypage',
        method: 'post',
        data: data
    })
}
//3.获取省份数据
export const GetProvinceList = () => {
    return request({
        url: '/web-control/area-data',
        method: 'get'
    })
}
//4.获取所属部门数据
export const GetLevelList = () => {
    return request({
        url: '/web-control/level-data',
        method: 'get'
    })
}
//专题政策 end

//获取收藏状态
export const GetCollectState = (data) => {
    return request({
        url: '/usercenter/ismycollection',
        method: 'post',
        params: data
    })
}

//添加收藏
export const AddCollect = (data) => {
    return request({
        url: 'usercenter/addcollection',
        method: 'post',
        params: data
    })
}

//取消收藏状态
export const DeleteCollect = (data) => {
    return request({
        url: '/usercenter/deletecollectionbyarticleid',
        method: 'post',
        params: data
    })
}

//获取相关专题
export const GetSpercias = (data) => {
    return request({
        url: '/spercia/getspercias',
        method: 'post',
        params: data
    })
}

//获取我的收藏
export const GetMycollect = (data) => {
    return request({
        url: '/usercenter/getmycollectionquerypage',
        method: 'post',
        data: data
    })
}

//后台取消收藏
export const DeleteCollectById = (data) => {
    return request({
        url: '/usercenter/deletecollection',
        method: 'post',
        params: data
    })
}

//获取关注列表
export const GetFollows = (data) => {
    return request({
        url: '/usercenter/getmyfollowquerypage',
        method: 'post',
        data: data
    })
}

//取消关注
export const DeleteFollow = (data) => {
    return request({
        url: '/usercenter/deletefollow',
        method: 'post',
        params: data
    })
}

//我的订阅
export const GetmyOrderRead = (data) => {
    return request({
        url: '/usercenter/getorderreadquerypage',
        method: 'post',
        data: data
    })
}
//地区
export const Getmyselectarea = () => {
    return request({
        url: '/web-control/area-data',
        method: 'get'
    })
}
export const Getmyselectlevel = () => {
    return request({
        url: '/web-control/level-data',
        method: 'get'
    })
}
//层级
export const GetHierarchyList = () => {
    return request({
        url: '/web-control/hierarchy-data',
        method: 'get'
    })
}
//类型
export const GetKindList = () => {
    return request({
        url: '/web-control/kind-data',
        method: 'get'
    })
}
//添加我的订阅
export const EditOrderRead = (data) => {
    return request({
        url: '/usercenter/editorderread',
        method: 'post',
        data: data
    })
}
//删除订阅
export const DelOrderRead = (data) => {
    return request({
        url: '/usercenter/delorderread',
        method: 'post',
        params: data
    })
}

export const IsMyFollow = (data) => {
    return request({
        url: '/usercenter/ismyfollow',
        method: 'post',
        params: data
    })
}

//添加关注
export const AddFollow = (data) => {
    return request({
        url: 'usercenter/addfollow',
        method: 'post',
        params: data
    })
}

//取消关注
export const DeleteFollowBySperciaId = (data) => {
    return request({
        url: '/usercenter/deletefollowbysperciaid',
        method: 'post',
        params: data
    })
}

// //获取用户信息
// export const GetUserInfo = () => {
//     return request({
//         url: '/usercenter/getusercenterinfo',
//         method: 'post'
//     })
// }

//根据Id获取专题信息
export const GetSperciasById = (data) => {
    return request({
        url: '/spercia/getsperciasbyid',
        method: 'post',
        params: data
    })
}

//11.获取个人信息
export const Loginout = () => {
    return request({
        url: '/userinfo/loginout',
        method: 'post',
    })
}
//是否登录
export const checkLogin = () => {
    return request({
        url: '/userinfo/checklogin',
        method: 'get',
    })
}

// 获取标签信息
export const getLabelByNoteId = (query) => {
    return request({
        url: '/Note/getlabelbynoteid',
        method: 'post',
        params: query
    })
}

// 添加标签
export const addUserLabel = (query) => {
    return request({
        url: '/Note/AddLabel',
        method: 'post',
        params: query
    })
}

// 删除标签关系
export const delUserLabel = (query) => {
    return request({
        url: '/Note/DelLabel',
        method: 'post',
        params: query
    })
}

// 获取对比数据
export const getContrast = (query) => {
    return request({
        url: '/Note/Contrast',
        method: 'post',
        params: query
    })
}

// 添加摘录信息
export const addExcerpts = (query) => {
    return request({
        url: '/Note/addExcerpts',
        method: 'post',
        data: query
    })
}

// 摘录列表信息
export const getExcerpts = (query) => {
    return request({
        url: '/Note/GetExcerpts',
        method: 'post',
        params: query
    })
}

// 修改摘录信息
export const modExcerpts = (query) => {
    return request({
        url: '/Note/ModExcerpts',
        method: 'post',
        data: query
    })
}

// 删除摘录信息
export const DelExcerpts = (query) => {
    return request({
        url: '/Note/delExcerpts',
        method: 'post',
        params: query
    })
}

//11.获取微信扫描二维码
export const wechatloginrequest = () => {
    return request({
        url: '/thirdpartylogin/wechatloginrequest',
        method: 'post',
    })
}
//wechat
export const wechatlogincallback = (data) => {
    return request({
        url: '/thirdpartylogin/wechatlogincallback',
        method: 'post',
        params: data
    })
}
//11.获取qq扫描二维码
export const qqloginrequest = () => {
    return request({
        url: '/thirdpartylogin/qqloginrequest',
        method: 'post',
    })
}
//qq
export const qqlogincallback = (data) => {
    return request({
        url: '/thirdpartylogin/qqlogincallback',
        method: 'post',
        params: data
    })
}
//绑定手机号-发送手机号验证码
export const bindphonesendcode = (data) => {
    return request({
        url: '/thirdpartylogin/bindphonesendcode',
        method: 'post',
        params: data
    })
}
//绑定手机号-执行绑定并登录
export const bindphone = (data) => {
    return request({
        url: '/thirdpartylogin/bindphone',
        method: 'post',
        params: data
    })
}

//标签统计查询
export const labelCountQuery = (data) => {
    return request({
        url: '/note/getlabelcountinfoquerylist',
        method: 'post',
        data: data
    })
}

//编辑笔记本
export const EditFolderInfo = (data) => {
    return request({
        url: '/note/editfolderinfo',
        method: 'post',
        data: data
    })
}

//笔记本列表
export const GetNoteBookQueryPage = (data) => {
    return request({
        url: '/note/getnotebookquerypage',
        method: 'post',
        data: data
    })
}

//删除笔记
export const DeleteNote = (data) => {
    return request({
        url: '/note/deletenote',
        method: 'post',
        params: data
    })
}

//根据文章获取笔记
export const AddArticleNote = (data) => {
    return request({
        url: '/note/addarticlenote',
        method: 'post',
        data: data
    })
}

//根据文章获取笔记
export const GetArticleNote = (data) => {
    return request({
        url: '/note/getarticlenote',
        method: 'post',
        params: data
    })
}

//根据id获取笔记
export const GetNote = (data) => {
    return request({
        url: '/note/getnote',
        method: 'post',
        params: data
    })
}

//获取笔记列表
export const GetNoteQueryPage = (data) => {
    return request({
        url: '/note/getnotequerypage',
        method: 'post',
        data: data
    })
}

//根据用户获取笔记本列表
export const GetFolderList = (data) => {
    return request({
        url: '/note/getfolderlist',
        method: 'post',
        params: data
    })
}

//设置笔记的笔记本属性
export const SetNoteFolder = (data) => {
    return request({
        url: '/note/setnotefolder',
        method: 'post',
        data: data
    })
}

//修改笔记
export const ModArticleNote = (data) => {
    return request({
        url: '/note/ModArticleNote',
        method: 'post',
        data: data
    })
}
//11.获取微信关注扫描二维码
export const wechatqrcodeurl = () => {
    return request({
        url: '/thirdpartylogin/qrcodeurl',
        method: 'post',
    })
}

export const getrange = () => {
    return request({
        url: '/web-control/range',
        method: 'get'
    })
}

//获取笔记星图数据
export const GetNoteStarMapData = (data) => {
    return request({
        url: '/note/getnotestarmapdata',
        method: 'post',
        data: data
    })
}

//获取订阅条件
export const GetOrderById = (data) => {
    return request({
        url: '/usercenter/getorderbyid',
        method: 'post',
        params: data
    })
}

//获取笔记思维导图数据
export const GetNoteMindMapData = (data) => {
    return request({
        url: '/note/getnotemindmapdata',
        method: 'post',
        params: data
    })
}
//微信网页授权登录
export const WebPageAuthorization = (data) => {
    return request({
        url: '/thirdpartylogin/webpageauthorization',
        method: 'post',
        params: data
    })

}
export const MphoneLogin = (data) => {
    return request({
        url: '/thirdpartylogin/mphoneLogin',
        method: 'post',
        data: data
    })

}
export const GetAnalysisBySearchResult = (data) => {
    return request({
        url: '/analysis/analysis-by-search-result',
        method: 'post',
        data: data
    })

}

export const GetAnalysisByIdResult = (data) => {
    return request({
        url: '/analysis/analysis-by-Id-result',
        method: 'post',
        data: data
    })

}

// 根据ID检索
export const getSearchListById = (data) => {
    return request({
        url: '/Search/search-list-by-id',
        method: 'post',
        data: data
    })
}

// 根据ID检索
export const Exportword = (data) => {
    return request({
        url: '/Analysis/exportanalysisword',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 视频检索
export const getVideoSearchList = (data) => {
    return request({
        url: '/Vedio/search-list',
        method: 'post',
        data: data
    })
}


export const getVideoSearchListIndex = (data) => {
    return request({
        url: '/Vedio/SearcListIndex',
        method: 'post',
        data: data
    })
}

export const getVideoSearchListIndexIndex = (data) => {
    return request({
        url: '/Vedio/SearcListIndexIndex',
        method: 'post',
        data: data
    })
}

// 视频详情
export const getVideoDetail = (query) => {
    return request({
        url: '/Vedio/detail',
        method: 'post',
        params: query
    })
}

// 添加相似
export const addSimilar = (data) => {
    return request({
        url: '/Similar/addsimilar',
        method: 'post',
        data: data
    })
}

// 添加相似
export const addSimilarById = (query) => {
    return request({
        url: '/Similar/addsimilarbyid',
        method: 'post',
        params: query
    })
}

// 相似列表
export const getSimilarList = (data) => {
    return request({
        url: '/Similar/getsimilarinfoquerypage',
        method: 'post',
        data: data
    })
}

// 删除相似
export const deleteSimilar = (query) => {
    return request({
        url: '/Similar/deletesimilar',
        method: 'post',
        params: query
    })
}


// 全文对照
export const getSimilarFullText = (query) => {
    return request({
        url: '/Similar/getfulltextcross',
        method: 'post',
        params: query
    })
}
//
export const ExportZip = (data) => {
    return request({
        url: '/Analysis/RenderHtmlReport',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
//微信取消当前订阅
export const CancleOrder = (query) => {
    return request({
        url: '/usercenter/cancleorder',
        method: 'post',
        params: query
    })
}

//日志记录
export const Recordlog = (query) => {
    return request({
        url: '/logs/recordlog',
        method: 'post',
        params: query
    })
}

//产创平台登录
export const SSOLogin = (data) => {
    return request({
        url: '/thirdpartylogin/sso-login',
        method: 'post',
        params: data
    })
}

//根据ip返回机构信息
export const InstitutionIp = () => {
    return request({
        url: '/thirdpartylogin/InstitutionIp',
        method: 'post',
    })
}

//绑定和解绑
export const InstitutionBind = (data) => {
    return request({
        url: '/thirdpartylogin/InstitutionBind',
        method: 'post',
        params: data
    })
}


//条文查询
export const getContrastIndex = (query) => {
    return request({
        url: '/note/ContrastIndex',
        method: 'post',
        params: query
    })
}


// 批量添加相似
export const addSimilarbatch = (data) => {
    return request({
        url: '/Similar/addsimilarbatch',
        method: 'post',
        data: data
    })
}


// 省份聚类下级机构
export const policynavigation_province = (query) => {
    return request({
        url: '/policynavigation/province',
        method: 'get',
        params: query
    })
}
// 中央机构
export const centrenavigation_centre = (query) => {
    return request({
        url: '/policynavigation/centreorgan',
        method: 'get',
        params: query
    })
}
//视频左侧聚类
export const SearcListIndexCluster = (data) => {
    return request({
        url: '/Vedio/SearcListIndexCluster',
        method: 'post',
        data: data
    })
}

// 热门政策
export const hotpolicy = (query) => {
    return request({
        url: '/logs/hotpolicy',
        method: 'get',
        params: query
    })
}

// 热门检索词
export const hotpolicysearchkey = (query) => {
    return request({
        url: '/logs/hotpolicysearchkey',
        method: 'get',
        params: query
    })
}


// 首页数据总量
export const gettotalcount = () => {
    return request({
        url: '/search/gettotalcount',
        method: 'get',
    })
}

//反馈
export const feedback = (data) => {
    return request({
        url: '/logs/addfeedback',
        method: 'post',
        data: data
    })
}