<template>
  <el-dialog :append-to-body="true" title="订阅提醒" :visible.sync="alertVisible" width="650px" @close="handleClose">
    <div class="subscribe-alert" v-loading="loading">
      <div class="tip"><i class="icon iconfont icon-dingyuetixing"></i>
        您有{{ ordernewCount }}条订阅动态更新，请及时查看，以免错过重要信息！</div>
      <div class="alert-list" v-if="orderreadnewList.filter(x => x.dayRefreshCount > 0).length > 0">
        <el-empty v-if="orderreadnewList.length === 0" description="暂无订阅动态！" :image-size="80"></el-empty>
        <template v-for="(item, index) in orderreadnewList">
          <div class="list-item" v-if="item.dayRefreshCount > 0" :key="index">
            <div class="_name" :title="item.orderName">{{ item.orderName }}</div>
            <div class="_rate"><el-rate :value="item.importantLevel" disabled>
              </el-rate></div>
            <div class="_count">{{ item.dayRefreshCount }}条</div>
            <div class="_op">
              <el-button size="mini" @click="handleSee(item)">立即查看</el-button>
            </div>
          </div>
        </template>
      </div>
      <div class="submit">
        <el-button type="info" @click="alertVisible = false">先逛逛</el-button>
        <div style="margin-top:20px;">
          <el-checkbox v-model="noAlert">不再弹窗提醒</el-checkbox>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  getorderreadnewlist,
} from "@/utils/orderreadnew";
import { islogined, islogin } from "@/utils/logincheck";
export default {
  components: {},
  props: ['visible', 'timestamp'],
  data () {
    return {
      loading: false,
      alertVisible: false,
      noAlert: true,
      subscribeVisible: true,
      orderreadnewList: [],//订阅提醒列表
      ordernewCount: 0,//订阅提醒数量
    }
  },
  computed: {},
  created () {
  },
  watch: {
    timestamp () {
      this.alertVisible = this.visible;
      if (this.$Common.getStorage("_subscribeDialog")) {
        this.noAlert = !this.$Common.getStorage("_subscribeDialog");
      }
      if (islogined()) {
        this.getOrderreadnewList();
      }
    }
  },
  methods: {
    //订阅提醒
    getOrderreadnewList () {
      this.loading = true;
      this.ordernewCount = 0;
      getorderreadnewlist({ PageIndex: 1, PageSize: 999 }).then(res => {
        if (res.data.data) {
          this.orderreadnewList = res.data.data.pageData.items;
          this.orderreadnewList.forEach(element => {
            this.ordernewCount += element.dayRefreshCount;
          });
        }
        this.loading = false;
      })
    },
    handleSee (obj) {
      //window.open('/Subscribe?id=' + obj.id);
      window.location.href='/Subscribe?id=' + obj.id;
      //this.$router.push({ name: 'Subscribe', query: { id: obj.id } });
      //this.alertVisible = false
    },
    handleClose () {
      this.$Common.setStorage("_subscribeDialog", !this.noAlert);
    }
  },
  mounted () {
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/var.scss";

.subscribe {}

.tip {
  margin-right: 10px;
  color: #d22706;

  i.iconfont {
    margin-right: 4px;
    font-size: 16px;
  }

  span {
    margin: 0 4px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
  }
}

.inner-title {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  font-size: $fontSizeBig;

  i.iconfont {
    font-size: 24px;
    margin-right: 4px;
    color: $colorMain;
  }
}

.subscribe-list {
  display: grid;
  gap: 28px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-bottom: 15px;
}

.subscribe-item {
  position: relative;
  height: 224px;
  padding: 16px 20px 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 8px rgba(1, 34, 88, .1);

  &:hover {
    color: #fff;
    background-color: $colorMain;

    * {
      color: #fff !important;
    }

    ._subject {
      color: $colorMain !important;
      background-color: #fff;

      label {
        color: $colorMain !important;
      }
    }

    ._date {
      i {
        color: #ffa033 !important;
      }
    }

    ._note {

      background-color: #fff;

      span {
        color: #000 !important;
      }

      button {
        color: #fff;
        border: 1px solid $colorMain;
        background-color: $colorMain;
      }
    }
  }

  ._name {
    margin-bottom: 8px;
    font-size: $fontSizeBig;
    line-height: 24px;
    color: #252b3a;

    i.icon {
      font-size: 20px;
      color: $colorMain;
    }
  }

  ._subject {
    display: inline-block;
    max-width: 100%;
    height: 22px;
    margin-bottom: 16px;
    padding: 0 10px;
    color: #fff;
    line-height: 22px;
    background-color: #ff794a;
    border-radius: 12px;
    @include clip;
  }

  ._unit {
    margin-bottom: 16px;
    color: #9fa1a6;
    @include clip;

    i.icon {
      margin-right: 4px;
      font-size: 14px;
      color: #c2c2c2;
    }

  }

  ._info {
    display: flex;
    align-items: center;
    justify-content: space-between;


  }

  ._star {
    display: flex;

    .el-rate {
      ::v-deep {
        i {
          margin-right: 0;
        }
      }
    }
  }

  ._date {
    color: #9fa1a6;

    i {
      margin-right: 4px;
      font-size: 14px;

    }
  }

  ._note {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background-color: #ffeeee;
    border-radius: 0 0 10px 10px;

    strong {
      margin: 0 2px;
      font-weight: bold;
      color: $colorMain !important;
    }

    button {
      display: inline-flex;
      justify-content: center;
      width: 72px;
      height: 22px;
      padding: 0;
      border: 1px solid #fff;
      background-color: #fff;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}

.recommend-list {
  display: grid;
  gap: 22px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.recommend-item {
  height: 152px;
  padding: 16px 20px;
  line-height: 25px;
  color: #000;
  background-color: #f8f9fc;
  border-radius: 10px;
  box-shadow: 1px 1px 8px rgba(1, 34, 88, .1);

  &:hover {
    button {
      color: #fff;
      border-color: #ff7a03;
      background-color: #ff7a03;
    }
  }

  label {
    color: #666666;
  }

  i {
    margin-right: 4px;
    color: #c2c2c2;
  }

  button {
    display: inline-flex;
    justify-content: center;
    width: 106px;
    height: 30px;
    padding: 0;
    line-height: 28px;
    border: 1px solid #b1a49a;
    background-color: transparent;
    border-radius: 6px;
    cursor: pointer;
  }

  ._info {
    span {
      margin: 0 2px;
      color: $colorMain;
      font-weight: bold;
    }
  }

  ._op {
    padding-top: 12px;
  }
}

.subscribe-alert {
  padding: 10px 50px;

  .tip {
    font-size: 16px;
    color: #1e1e1e;
    background-color: transparent;

    span {
      color: $colorMain;
    }

    i {
      font-size: 18px;
      color: $colorMain;
    }
  }

  .alert-list {
    padding: 10px 15px;
    background-color: #f8f9fc;
    border-radius: 10px;

    .list-item {
      display: flex;
      align-items: center;
      height: 46px;
      border-bottom: 1px solid #e9eaeb;

      &:last-child {
        border-bottom: 0;
      }

      ._name {
        flex: 1;
        color: #000000;
        @include clips(1);
      }

      ._rate {
        width: 100px;
        min-width: 100px;
        text-align: center;

        .el-rate {
          ::v-deep {
            i {
              margin-right: 0;
            }
          }
        }
      }

      ._count {
        width: 80px;
        text-align: center;
      }

      ._op {
        .el-button {
          width: 70px;
          height: 20px;
          padding: 0;
          line-height: 18px;
          color: #ff7a03;
          background-color: #ffede6;
          border-color: #ff7a03;
        }
      }
    }
  }

  .submit {
    margin-top: 30px;
    text-align: center;

    .el-button {
      width: 180px;
      height: 40px;
      color: #000;
      background-color: #e2e2e2;
      border-color: #e2e2e2;
    }
  }
}
</style>
